import {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toEmptyStringIfNull } from "../Utility";

const LABEL_SAVE = "Speichern";
const LABEL_ABORT = "Abbrechen"

const INTEGER_REGEX = new RegExp('^[0-9]*$'); ; 

const removeLeadingZeros = (str) => {
    if(str == "0") {
        return str;
    }
    else {
        return str.replace(/^0+/, '');
    }
}

export default function EditCurrencyDialog(props) {

    const [curValue, setCurValue] = useState(null)
 
    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField 
                    value={toEmptyStringIfNull(curValue)} 
                    onChange={
                        (e) => {
                            const value = e.target.value;
                            if(INTEGER_REGEX.test(value)) { 
                                setCurValue(removeLeadingZeros(e.target.value))
                            }
                            else {
                                setCurValue(curValue)
                            }
                        }  
                    }       
                    id="name" 
                    variant="standard" 
                    fullWidth 
                    autoFocus
                />
            </DialogContent>
            <DialogActions> 
                <Button onClick={() => {props.onClose(); setCurValue(null)}}>{LABEL_ABORT}</Button>
                <Button onClick={() => {props.onSave(curValue); setCurValue(null)}}>{LABEL_SAVE}</Button>
            </DialogActions>
        </Dialog> 
    ) 
}     