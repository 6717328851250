import { HTTP_STATUS_SUCCESS, buildUrl, formatParam } from "../App";
import { MSG_ERROR_OCCURED } from "../Utility";


export const fetchPurchasesCsvAPI = (username, password) => {
    return fetch(buildUrl("reporting/purchases?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})
}   

export const fetchSalesCsvAPI = (username, password) => {
    return fetch(buildUrl("reporting/sales?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})
}   