
import { useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { handleResponse } from '../Utility';
import { findProductInvoicesAPI, findTransactionInvoicesAPI, reissueProductInvoiceAPI, reissueTransactionInvoiceAPI } from '../api/lead_api';
import { fetchBillableItemAPI, fetchInvoiceAPI } from '../api/invoice_api';
import { PRIMARY_COLOR } from '../App';
import { NavLink, useParams } from 'react-router-dom';
import { OpenInNew } from '@material-ui/icons';

const LABEL_DATE = "Datum";
const LABEL_INVOICE_NO = "Rechnungsnummer";
const LABEL_NET_AMOUNT = "Betrag (netto)"
const LABEL_GROSS_AMOUNT = "Betrag (brutto)"
const LABEL_ACTIONS = "Aktionen"
const LABEL_CURRENCY = "EUR"

const LABEL_INVOICE_TRANSACTION = "Rechnungen (Transaktion)";
const LABEL_INVOICE_PRODUCT = "Rechnungen (Produkt)";

export function InvoicingWrapper(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const { leadId } = useParams();

    const [billableItem, setBillableItem] = useState(null)
    const [updateBillableItem, setUpdateBillableItem] = useState(true);

    useEffect(() => {
        if (updateBillableItem) {
            handleResponse(fetchBillableItemAPI(contextAPI.username, contextAPI.password, leadId), (billableItem) => setBillableItem(billableItem), appAPI.showMessage);
            setUpdateBillableItem(false);
        }
    })

    let key = 0;

    return billableItem == null ? null : (
        <Box style={{ marginTop: "50px" }}>
            <Invoicing
                appAPI={appAPI}
                contextAPI={contextAPI}
                billableItem={billableItem}
                key={key++}
            />
        </Box>
    )
}

export function Invoicing(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const billableItem = props.billableItem;

    const isAdmin = contextAPI.admin;
    const isSeller = (billableItem.sellerId == contextAPI.id);
    const isBuyer = (billableItem.buyerId == contextAPI.id);

    const [productInvoiceList, setProductInvoiceList] = useState([])
    const [transactionInvoiceList, setTransactionInvoiceList] = useState([])
    const [updateProductInvoiceList, setUpdateProductInvoiceList] = useState(isSeller || isBuyer)
    const [updateTransactionnvoiceList, setUpdateTransactionInvoiceList] = useState(isAdmin || isSeller)

    useEffect(() => {
        if (updateProductInvoiceList) {
            handleResponse(findProductInvoicesAPI(contextAPI.username, contextAPI.password, billableItem.id), (invoiceList) => setProductInvoiceList(invoiceList), appAPI.showMessage);
            setUpdateProductInvoiceList(false);
        }
        if (updateTransactionnvoiceList) {
            handleResponse(findTransactionInvoicesAPI(contextAPI.username, contextAPI.password, billableItem.id), (invoiceList) => setTransactionInvoiceList(invoiceList), appAPI.showMessage);
            setUpdateTransactionInvoiceList(false);
        }
    })

    const buildTitle = () => {
        var prefix = "Lead: "
        if (billableItem.categoryName != null) {
            prefix = billableItem.categoryName + ": "
        }
        return prefix + billableItem.id;
    }


    return (
        <Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    style={
                        {
                            backgroundColor: PRIMARY_COLOR,
                            color: "white"
                        }
                    }
                >
                    {
                        billableItem.status == "SOLD" || billableItem.status == "INVALIDATION_PROPOSED" ?
                            <NavLink to={"/leads/" + billableItem.id} style={{ textDecoration: 'none', color: 'unset' }} >
                                <IconButton style={{ height: "5px" }}>
                                    <OpenInNewIcon style={{ color: "white" }} />
                                </IconButton>
                            </NavLink >
                            :
                            null
                    }

                    <Typography variant="span" style={{ fontFamily: "Arial, Helvetica", marginTop: "2px" }}>
                        {buildTitle()}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ margin: "0px", padding: "0px" }}>
                    {
                        (isAdmin || isSeller) ?
                            <InvoiceTable
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                type={"TRANSACTION"}
                                invoiceList={transactionInvoiceList}
                                isAdmin={isAdmin}
                                isSeller={isSeller}
                                title={"Transaktion"}
                                lead={billableItem}
                                update={() => setUpdateTransactionInvoiceList(true)}
                            />
                            :
                            null
                    }
                    {
                        (isBuyer || isSeller) ?
                            <InvoiceTable
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                type={"PRODUCT"}
                                invoiceList={productInvoiceList}
                                isAdmin={isAdmin}
                                isSeller={isSeller}
                                title={"Produkt"}
                                lead={billableItem}
                                update={() => setUpdateProductInvoiceList(true)}
                            />
                            :
                            null
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export function InvoicingDetails(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const lead = props.lead;

    const isAdmin = contextAPI.admin;
    const isSeller = (lead.creatorId == contextAPI.id);
    const isBuyer = (lead.buyerId == contextAPI.id);
    const showTransactionInvoices = props.showTransactionInvoices;

    const [productInvoiceList, setProductInvoiceList] = useState([])
    const [transactionInvoiceList, setTransactionInvoiceList] = useState([])
    const [updateProductInvoiceList, setUpdateProductInvoiceList] = useState(isSeller || isBuyer)
    const [updateTransactionnvoiceList, setUpdateTransactionInvoiceList] = useState(isAdmin || isSeller)

    useEffect(() => {
        if (updateProductInvoiceList) {
            handleResponse(findProductInvoicesAPI(contextAPI.username, contextAPI.password, lead.id), (invoiceList) => setProductInvoiceList(invoiceList), appAPI.showMessage);
            setUpdateProductInvoiceList(false);
        }
        if (updateTransactionnvoiceList) {
            handleResponse(findTransactionInvoicesAPI(contextAPI.username, contextAPI.password, lead.id), (invoiceList) => setTransactionInvoiceList(invoiceList), appAPI.showMessage);
            setUpdateTransactionInvoiceList(false);
        }
    })

    return (
        <Grid container spacing={0}>
            {showTransactionInvoices && (isAdmin || isSeller) ?
                <Grid item xs={12}>
                    <Box style={{ padding: "10px", backgroundColor: "#1976d2", color: "white" }}>
                        Transaktion
                    </Box>
                    <InvoiceTable
                        appAPI={appAPI}
                        contextAPI={contextAPI}
                        type={"TRANSACTION"}
                        invoiceList={transactionInvoiceList}
                        isAdmin={isAdmin}
                        isSeller={isSeller}
                        lead={lead}
                    />
                </Grid>
                :
                null
            }
            {isBuyer || isSeller ?
                <Grid item xs={12}>
                    <Box style={{ padding: "10px", backgroundColor: "#1976d2", color: "white" }}>
                        Produkt
                    </Box>
                    <InvoiceTable
                        appAPI={appAPI}
                        contextAPI={contextAPI}
                        type={"PRODUCT"}
                        invoiceList={productInvoiceList}
                        isAdmin={isAdmin}
                        isSeller={isSeller}
                        lead={lead}
                    />
                </Grid>
                :
                null
            }
        </Grid>
    )
}

function InvoiceTable(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const lead = props.lead;

    const update = props.update;

    const invoiceList = props.invoiceList;
    const type = props.type

    const formatCurrencyAmount = (value) => {
        value = value.replace(".", ",")
        if(value.includes(",")) {
            let suffix = value.split(',');
            if(suffix[1].length == 1) {
                value = value + "0";
            } 
        }
        else {
            value = value + ",00"
        }
        return value;
    }

    const reissueProductInvoice = () => {
        handleResponse(reissueProductInvoiceAPI(contextAPI.username, contextAPI.password, lead.id), () => update(), appAPI.showMessage);
    }


    const reissueTransactionInvoice = () => {
        handleResponse(reissueTransactionInvoiceAPI(contextAPI.username, contextAPI.password, lead.id), () => update(), appAPI.showMessage);
    }

    const download = (purchaseId, priceOfferTimestamp, invoiceDateTimestamp, invoiceNo) => {
        handleResponse(fetchInvoiceAPI(contextAPI.username, contextAPI.password, purchaseId, priceOfferTimestamp, invoiceDateTimestamp, type), (response) => {
            var blob = new Blob([response], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "invoice-" + invoiceNo + ".pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, appAPI.showMessage)
    }

    return (
        <Box>
            <Box style={{ padding: "15px", backgroundColor: "rgb(25, 118, 210)", color: "white" }}>
                {
                    props.isSeller && props.type == "PRODUCT" ?
                        <IconButton onClick={reissueProductInvoice} style={{ color: "white", height: "12px" }} disabled={invoiceList.length == null || invoiceList.length == 0}>
                            <ReplayIcon />
                        </IconButton>
                        :
                        null
                }
                {
                    props.isAdmin && props.type == "TRANSACTION" ?
                        <IconButton onClick={reissueTransactionInvoice} style={{ color: "white", height: "12px" }} disabled={invoiceList.length == null || invoiceList.length == 0}>
                            <ReplayIcon />
                        </IconButton>
                        :
                        null
                } 
                {props.title}
            </Box>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{LABEL_DATE}</TableCell>
                        <TableCell>{LABEL_INVOICE_NO}</TableCell>
                        <TableCell>{LABEL_NET_AMOUNT}</TableCell>
                        <TableCell>{LABEL_GROSS_AMOUNT}</TableCell>
                        <TableCell>{LABEL_ACTIONS}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        invoiceList.map(invoice => {
                            return (
                                <TableRow>
                                    <TableCell>{invoice.invoiceDate}</TableCell>
                                    <TableCell>{invoice.invoiceNo}</TableCell>
                                    <TableCell>{formatCurrencyAmount(invoice.amountNet.toString()) + " " + LABEL_CURRENCY}</TableCell>
                                    <TableCell>{formatCurrencyAmount(invoice.amountGross.toString()) + " " + LABEL_CURRENCY}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" onClick={() => download(invoice.purchaseId, invoice.priceOfferTimestamp, invoice.invoiceDateTimestamp, invoice.invoiceNo)}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    )
} 