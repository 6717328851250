import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import { blockBuyerAPI, fetchBlockedBuyersAPI, fetchBuyersAPI, unblockBuyerAPI } from '../api/user_api';
import { handleResponse } from '../Utility';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const renderFirm = (buyer) => buyer.firm != null ? "(" + buyer.firm + ")" : "";


export default function PartnerManagement(props) {
    
    const [initialized, setInitialized] = React.useState(false);
    const [buyerList, setBuyerList] = React.useState([]);
    const [blockedBuyerList, setBlockedBuyerList] = React.useState([]);
    
    let appAPI = props.appAPI;
    let contextAPI = props.contextAPI;
    let userId = props.userId;

    React.useEffect(() => {
        if(!initialized) {
            handleResponse(fetchBuyersAPI(contextAPI.username, contextAPI.password), (response) => setBuyerList(response), appAPI.showMessage);
            handleResponse(fetchBlockedBuyersAPI(contextAPI.username, contextAPI.password, userId), (response) => setBlockedBuyerList(response.map(user => user.id)) , appAPI.showMessage);
            setInitialized(true);
        }
    }) 

    const handleChange = (event) => {
        const added = []
        const removed = []
        
        const newBlockedBuyerList = event.target.value;
        for(let blockedBuyerIndex = 0; blockedBuyerIndex < blockedBuyerList.length; blockedBuyerIndex++) {
            let found = false;
            for(let newBlockedBuyerIndex = 0; newBlockedBuyerIndex < newBlockedBuyerList.length; newBlockedBuyerIndex++) {
                if(blockedBuyerList[blockedBuyerIndex] == newBlockedBuyerList[newBlockedBuyerIndex]) {
                    found = true;
                    break;
                }
            }
            if(!found) {
                removed.push(blockedBuyerList[blockedBuyerIndex])
            }
        }

        for(let newBlockedBuyerIndex = 0; newBlockedBuyerIndex < newBlockedBuyerList.length; newBlockedBuyerIndex++) {
            let found = false;
            for(let blockedBuyerIndex = 0; blockedBuyerIndex < blockedBuyerList.length; blockedBuyerIndex++) {
                if(blockedBuyerList[blockedBuyerIndex] == newBlockedBuyerList[newBlockedBuyerIndex]) {
                    found = true;
                    break;
                }
            }
            if(!found) {
                added.push(newBlockedBuyerList[newBlockedBuyerIndex]);
            }
        }

        for(let addedIndex = 0; addedIndex < added.length; addedIndex++) {           
            handleResponse(blockBuyerAPI(contextAPI.username, contextAPI.password, userId, added[addedIndex]), (response) => {}, appAPI.showMessage);
        }

        for(let removedIndex = 0; removedIndex < removed.length; removedIndex++) {           
            handleResponse(unblockBuyerAPI(contextAPI.username, contextAPI.password, userId, removed[removedIndex]), (response) => {}, appAPI.showMessage);
        }

        setBlockedBuyerList(newBlockedBuyerList);
    }


    return (
        <Grid container > 
            <Grid xs={12}>
                <FormControl style={{width: "100%"}}>
                    <InputLabel id="block-list-label">Blockierte Käufer</InputLabel>
                    <Select
                        labelId="block-list-label"
                        multiple
                        value={blockedBuyerList}
                        onChange={handleChange}
                        input={<OutlinedInput label="Blockierte Käufer" />}
                        style={{display: "inline-block", width: "100%"}}
                    >
                        {buyerList.map((buyer) => (
                            <MenuItem
                                key={buyer.id}
                                value={buyer.id}
                            >
                                {buyer.prename +  " " + buyer.lastname + " " + renderFirm(buyer)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}