import {useState} from "react";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toEmptyStringIfNull } from "../Utility";

const LABEL_SAVE = "Speichern";
const LABEL_ABORT = "Abbrechen"
const LABEL_NEW_PASSWORD = "Neues Password"
const LABEL_NEW_REPETITION = "Wiederholung"


export default function EditPasswordDialog(props) {

    const [curValue, setCurValue] = useState(null)
    const [curRepeatedValue, setCurRepeatedValue] = useState(null)

    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent> 
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <TextField label={LABEL_NEW_PASSWORD} value={toEmptyStringIfNull(curValue)} onChange={(e) => setCurValue(e.target.value)} variant="standard" type="password" fullWidth autoFocus/>
                    </Grid>
                    <Grid item xs={12} >
                        <TextField label={LABEL_NEW_REPETITION} value={toEmptyStringIfNull(curRepeatedValue)} onChange={(e) => setCurRepeatedValue(e.target.value)} variant="standard"  type="password"  fullWidth autoFocus/>
                    </Grid>
                </Grid>
            </DialogContent> 
            <DialogActions> 
                <Button onClick={() => {props.onClose(); setCurValue(null); setCurRepeatedValue(null)}}>{LABEL_ABORT}</Button>
                <Button onClick={() => {props.onSave(curValue); setCurValue(null); setCurRepeatedValue(null)}} disabled={curValue === null || curValue !== curRepeatedValue || curValue.length < 8}>{LABEL_SAVE}</Button>
            </DialogActions>
        </Dialog>  
    ) 
}     