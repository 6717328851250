import { Box, Typography } from "@mui/material";
import { PRIMARY_COLOR } from "../App";

const HEADING_IMPRINT = "Impressum"

export default function Imprint(props) {

    return(
        <Box style={{fontFamily: "Arial, Helvetica"}}>
            <Typography variant="h2" style={{color: PRIMARY_COLOR}}>{"Betreiber-"}</Typography>
            <Typography variant="h1" style={{color: PRIMARY_COLOR}}>{HEADING_IMPRINT}</Typography>
            <Box style={{height: "50px"}}/>
            <Typography variant="p">
                <b>Philipp Kunz</b><br/>
            </Typography>
            <Typography variant="p">
                Generalagent der HanseMerkur Krankenversicherung AG<br/>
            </Typography>
            <br/>
            <Typography variant="p">
                Riederichstraße 22<br/>
                72661 Grafenberg<br/>
                Telefon: 07123 3762207<br/>
                Handy: 0176 48012259<br/>
                E-Mail: philipp.kunz@hansemerkur.de<br/>
                Versicherungsvertreter gemäß § 34 d (4) GewO<br/>
                Vermittlerregisternummer: D-VRCU-TV2IZ-40<br/>
            </Typography>
            <br/>
            <Typography variant="p">
                Als Ausschließlichkeitsvertreter der HanseMerkur Krankenversicherung AG vermittle ich in den Sparten 
                <ul>
                    <li>Kranken,</li>
                    <li>Leben,</li>
                    <li>Unfall,</li>
                    <li>Sach,</li>
                    <li>Haftpflicht,</li>
                    <li>Kraftfahrt,</li>
                    <li>Reise</li>
                </ul>
                Produkte der HanseMerkur Krankenversicherung AG sowie der
                <ul>
                    <li>HanseMerkur Lebensversicherung AG</li>
                    <li>HanseMerkur Allgemeine Versicherung AG</li>
                    <li>HanseMerkur Reiseversicherung AG</li>
                    <li>Advigon Versicherung AG (Vaduz, Liechtenstein)</li>
                </ul>
                sowie zusätzlich Rechtsschutzversicherungen der Itzehoer Versicherung/Brandgilde von 1691 Versicherungsverein a.G.<br/><br/>
                Ich bin an keinem Versicherungsunternehmen beteiligt und an meiner Versicherungsagentur ist kein Versicherungsunternehmen beteiligt. Ich biete Ihnen im Rahmen meiner Vermittlungstätigkeit eine Beratung an. Für meine Tätigkeit erhalte ich ausschließlich Provisionen sowie andere Zuwendungen, die in der Versicherungsprämie enthalten sind.
                <br/>
            </Typography>
            <br/>
            <Typography variant="p">
                <b>Anschrift der Registrierungsstelle</b><br/>
                Deutscher Industrie- und Handelskammertag (DIHK)<br/>
                Breite Straße 29, 10178 Berlin<br/>
                Telefon: (0180) 600 58 50<br/>
                (0,20 EUR/Anr. aus den Festnetzen / Mobilfunk max. 0,60 EUR/Anruf)<br/>
                www.vermittlerregister.info<br/>
            </Typography>
            <br/>
            <Typography variant="p">
                <b>Schlichtungsstellen</b><br/>
                Für Lebens- und Sachversicherungen:<br/>
                Versicherungs-Ombudsmann e.V.<br/>
                Postfach 08 06 32, 10006 Berlin<br/>
                Für private Kranken- und Pflegeversicherungen:<br/>
                Ombudsmann - Private Kranken- und Pflegeversicherung,<br/>
                Postfach 06 02 22, 10052 Berlin<br/>
            </Typography>
        </Box>
    )

}