import { Box, Grid, Paper } from "@mui/material";
import User from "../fragments/User";
import { PRIMARY_COLOR } from "../App";


export default function Profile(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const userId = props.userId;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box style={{height: "50px"}} />
            </Grid>
            <Grid item xs={12}>
                <User
                    appAPI={appAPI}
                    contextAPI={contextAPI}
                    userId={userId}
                />
            </Grid>
        </Grid>
    )
}
