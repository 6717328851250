import { useEffect, useState } from "react";
import { fetchAuctionsAPI } from "../api/auction_api";
import { handleResponse } from "../Utility";
import { Box, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import Auction from "../fragments/Auction";
import RefreshButton from "../components/RefreshButton";


const LABEL_SEARCH = "Suchen";


const MSG_NO_AUCTIONS = "Keine verfügbaren Auktionen";

 
export default function Auctions(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const [auctionList, setAuctionList] = useState([]);
    const [updateAuctionList, setUpdateAuctionList] = useState(true);

    
    const filteredAuctionList = auctionList;

    useEffect(() => {

        const doUpdateAuctionList = async () => {
            handleResponse(fetchAuctionsAPI(contextAPI.username, contextAPI.password), (response) => {
                setAuctionList(response);
                setUpdateAuctionList(false)
            }, appAPI.showMessage)
        }

        if (updateAuctionList) {
            doUpdateAuctionList();
        }
    })

    return ( 
        <Box>
            {
                updateAuctionList ?
                    <LinearProgress />
                    :
                    (

                        <Grid container>

                            <Grid item xs={12}>
                                <Box style={{ height: "50px" }} />
                                {
                                    filteredAuctionList.length > 0 ?
                                        filteredAuctionList.map((auctionId) => 
                                            <Auction
                                                key={auctionId} 
                                                auctionId={auctionId}
                                                appAPI={appAPI}
                                                contextAPI={contextAPI}
                                                reload={() => setUpdateAuctionList(true)}
                                            />
                                        )
                                        :
                                        <Typography variant='p'>{MSG_NO_AUCTIONS}</Typography>
                                }
                            </Grid>
                        </Grid>
                    )
            }
        </Box>
    )
}

/*
                            <Grid item xs={12}>
                                <TextField 
                                    value={searchTerm}
                                    label={LABEL_SEARCH}
                                    variant="standard"
                                    onChange={(e) => {setSearchTerm(e.target.value)}}
                                    fullWidth 
                                    disabled={auctionList.length == 0}
                                />
                            </Grid>
*/