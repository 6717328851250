import { Button, Grid, TextField } from "@mui/material";
import { SECONDARY_COLOR } from "../App";
import { useEffect, useState } from "react";
import EditCurrencyDialog from "../components/EditCurrencyDialog";
import { handleResponse } from "../Utility";
import { fetchUserAPI, saveBudgetAPI } from "../api/user_api";

const LABEL_CHANGE_BUDGET = "Budget ändern"

export default function Budget(props) {

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI
    const userId = props.userId

    const [open, setOpen] = useState(false);
    const [budget, setBudget] = useState(0);
    const [updateBudget, setUpdateBudget] = useState(true);

    const onClose = () => {
        setOpen(false);

    }

    const onSave = (value) => {
        setOpen(false);
        handleResponse(saveBudgetAPI(userId, contextAPI.username, contextAPI.password, value === "" ? "0" : value), (response) => {
            setUpdateBudget(true)
        }, appAPI.showMessage)
    }  
 
    useEffect(() => {
        
        const doUpdateBudget = async () => {
            handleResponse(fetchUserAPI(userId, contextAPI.username, contextAPI.password), (response) => {
                if(response != null) {
                    setBudget(response.subscriberBudget);
                }
                setUpdateBudget(false)
            }, appAPI.showMessage)
        }

        if(updateBudget) { 
            doUpdateBudget();
        }
    })
 

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField 
                    value={budget != null ?  budget + " EUR" : ""}
                    onChange={() => { }}
                    variant="standard"
                    fullWidth
                    autoFocus
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => {setOpen(true)}} style={{ backgroundColor: SECONDARY_COLOR }}>
                    {LABEL_CHANGE_BUDGET}
                </Button>
                <EditCurrencyDialog 
                    open={open}
                    onClose={onClose}
                    onSave={onSave}
                    dialogTitle={LABEL_CHANGE_BUDGET}
                />
            </Grid> 
        </Grid>
    )
 
     
}  