import { useParams } from "react-router-dom";
import Auction from "../fragments/Auction";
import { Box } from "@mui/material";

export default function AuctionWrapper(props) {

    const { auctionId } = useParams();

    return (
        <Box style={{ marginTop: "50px" }}>
            <Auction
                appAPI={props.appAPI}
                contextAPI={props.contextAPI}
                auctionId={auctionId}
            />
        </Box>
    )
}

