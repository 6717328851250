import { HTTP_STATUS_SUCCESS, buildUrl, formatParam } from "../App";
import { MSG_ERROR_OCCURED } from "../Utility";

export const authenticate = async (username, password, showMessage) => {
    let context = null;
    try {
        const response = await fetch(buildUrl("authentication/authenticate?username=" + formatParam(username) + "&password=" + formatParam(password)))
        if(response.status === HTTP_STATUS_SUCCESS) {
            context = await response.json();
        }  
        else {
            showMessage(MSG_ERROR_OCCURED + response.status)
        }   
    } catch(e) {   
        showMessage(e.toString());  
    } 
    return context;
}    

export const fetchUserAPI = (userId, username, password) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "?username=" + formatParam(username)+ "&password=" + formatParam(password)));
}  

export const fetchUserImprintAPI = (userId) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/imprint"));
}  


export const fetchUsersAPI = (username, password) => {
    return fetch(buildUrl("user/all?username=" + formatParam(username)+ "&password=" + formatParam(password)));
}   

export const createUserAPI = (username, password, newUsername, newPassword, emailAddress, firstname, lastname, isAdmin, isCallAgent, isSubscriber, isPurchaser ) => {
    fetch(buildUrl("user/new"
                + "?username=" + formatParam(username) 
                + "&password=" + formatParam(password) 
                + "&newUsername=" + formatParam(newUsername)
                + "&newPassword=" + formatParam(newPassword)
                + "&emailAddress=" + formatParam(emailAddress)
                + "&firstName=" + formatParam(firstname)
                + "&lastName=" + formatParam(lastname)
                + "&isAdmin=" + formatParam(isAdmin)
                + "&isCallAgent=" + formatParam(isCallAgent)
                + "&isSubscriber=" + formatParam(isSubscriber)
                + "&isPurchaser=" + formatParam(isPurchaser))
                , {method: "PUT"})
}

export const saveUsernameAPI = (userId, username, password, newUsername) => {
    return fetch(buildUrl("authentication/" + userId + "/username?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newUsername=" + formatParam(newUsername)), {method: "PUT"})
}   

export const savePasswordAPI = async (userId, username, password, newPassword) => {
    return fetch(buildUrl("authentication/" + formatParam(userId) + "/password?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newPassword=" + formatParam(newPassword)), {method: "PUT"});
}  

export const saveFirmAPI = (userId, username, password, newFirm) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/firm?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newFirm=" + formatParam(newFirm)), {method: "PUT"})
} 

export const savePrenameNameAPI = (userId, username, password, newFirstName) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/firstName?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newFirstName=" + formatParam(newFirstName)), {method: "PUT"})
} 

export const saveLastnameNameAPI = (userId, username, password, newLastName) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/lastName?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newLastName=" + formatParam(newLastName)), {method: "PUT"})
} 

export const saveStreetAPI = (userId, username, password, newValue) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/street?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(newValue)), {method: "PUT"})
} 

export const saveHouseNoAPI = (userId, username, password, newValue) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/houseNo?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(newValue)), {method: "PUT"})
} 

export const saveZipCodeAPI = (userId, username, password, newValue) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/zipCode?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(newValue)), {method: "PUT"})
} 

export const saveTownAPI = (userId, username, password, newValue) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/town?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(newValue)), {method: "PUT"})
} 

export const saveCountryAPI = (userId, username, password, newCountry) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/country?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newCountry=" + formatParam(newCountry)), {method: "PUT"})
} 


export const saveEmailAddressAPI = (userId, username, password, emailAddress) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/emailAddress?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newEmailAddress=" + formatParam(emailAddress)), {method: "PUT"});
}


export const savePhoneNumberAPI = (userId, username, password, phoneNumber) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/phoneNumber?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newPhoneNumber=" + formatParam(phoneNumber)), {method: "PUT"});
}

export const saveImprintAPI = (userId, username, password, imprint) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/imprint?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newImprint=" + formatParam(imprint)), {method: "PUT"})
}

export const disableAPI = (userId, username, password) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/disable?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "PUT"})
}

export const saveIsAdminAPI = (userId, username, password, isAdmin) => {
    return  fetch(buildUrl("user/" + formatParam(userId) + "/isAdmin?username=" + formatParam(username) + "&password=" + formatParam(password) + "&isAdmin=" + formatParam(isAdmin)), {method: "PUT"})
}

export const saveIsPurchaserAPI = (userId, username, password, isPurchaser) => {
    return  fetch(buildUrl("user/" + formatParam(userId) + "/isPurchaser?username=" + formatParam(username) + "&password=" + formatParam(password) + "&isPurchaser=" + formatParam(isPurchaser)), {method: "PUT"})
}

export const saveIsSubscriberAPI = (userId, username, password, isSubscriber) => {
    return  fetch(buildUrl("user/" + formatParam(userId) + "/isSubscriber?username=" + formatParam(username) + "&password=" + formatParam(password) + "&isSubscriber=" + formatParam(isSubscriber)), {method: "PUT"})
}

export const saveIsCallAgentAPI = (userId, username, password, isCallAgent) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/isCallAgent?username=" + formatParam(username) + "&password=" + formatParam(password) + "&isCallAgent=" + formatParam(isCallAgent)), {method: "PUT"})
}




export const saveIsVatIdAPI = (userId, username, password, vatId) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/vatId?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(vatId)), {method: "PUT"})
}

export const saveTaxIdAPI = (userId, username, password, taxId) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/taxId?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(taxId)), {method: "PUT"})
}

export const saveVatRateAPI = (userId, username, password, vatRate) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/vatRate?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(vatRate)), {method: "PUT"})
}

export const saveBankAPI = (userId, username, password, bank) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/bank?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(bank)), {method: "PUT"})
}

export const saveBICAPI = (userId, username, password, bic) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/bic?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(bic)), {method: "PUT"})
}

export const saveIBANAPI = (userId, username, password, iban) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/iban?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(iban)), {method: "PUT"})
}

export const saveTaxExemptionAPI = (userId, username, password, taxExemption) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/taxExemption?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(taxExemption)), {method: "PUT"})
}
export const saveNonRegisteredSoleTraderAPI = (userId, username, password, nonRegistered) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/nonRegisteredSoleTrader?username=" + formatParam(username) + "&password=" + formatParam(password) + "&newValue=" + formatParam(nonRegistered)), {method: "PUT"})
}




export const fetchUserAssessmentsAPI = (userId, username, password) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/averageAssessments?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})

}

export const fetchUserCanSellAPI = (userId, username, password) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/canSell?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})
}

export const fetchUserCanBuylAPI = (userId, username, password) => {
    return   fetch(buildUrl("user/" + formatParam(userId) + "/canBuy?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})
}

export const saveBudgetAPI = async (userId, username, password, budget) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/budget?username=" + formatParam(username) + "&password=" + formatParam(password) + "&budget=" + formatParam(budget)), {method: "PUT"})
}

export const savePortraitURLAPI = async (userId, username, password, portraitUrl) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/portrait?username=" + formatParam(username) + "&password=" + formatParam(password) + "&portraitUrl=" + formatParam(portraitUrl)), {method: "PUT"})
}
 
export const acceptOfferAPI = (userId, username, password) => {
    return fetch(buildUrl("contract/" + formatParam(userId) + "/accept?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "PUT"})
} 

export const fetchBuyersAPI = async (username, password) => {
    return fetch(buildUrl("user/buyers?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})

}

export const fetchBlockedBuyersAPI = async (username, password, userId) => {
    return fetch(buildUrl("user/" + formatParam(userId) + "/blockedBuyers?username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "GET"})

}

export const blockBuyerAPI = async (username, password, sellerId, buyerId) => {
    return fetch(buildUrl("user/" + formatParam(sellerId) + "/blockages?buyerId=" + formatParam(buyerId) + "&username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "PUT"})
}

export const unblockBuyerAPI = async (username, password, sellerId, buyerId) => {
    return fetch(buildUrl("user/" + formatParam(sellerId) + "/blockages?buyerId=" + formatParam(buyerId) + "&username=" + formatParam(username) + "&password=" + formatParam(password)), {method: "DELETE"})
}