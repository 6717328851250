import { buildUrl, formatParam } from "../Utility"


export const fetchInvoiceAPI = (username, password, purchaseId, priceOfferTimestamp, invoiceDateTimestamp, type) => {
    return fetch(buildUrl("invoicing/" +  formatParam(purchaseId) + "/" + formatParam(priceOfferTimestamp) + "/" + formatParam(invoiceDateTimestamp) + "/" + formatParam(type) + "?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
}

export const fetchBillableItemsAPI = (username, password) => {
    return fetch(buildUrl("invoicing/all?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
}

export const fetchBillableItemAPI = (username, password, leadId) => {
    return fetch(buildUrl("invoicing/" + formatParam(leadId) + "?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
}
