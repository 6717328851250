import {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { buildUrl, HTTP_STATUS_SUCCESS } from "../App";
import { toEmptyStringIfNull } from "../Utility";

const LABEL_SAVE = "Speichern";
const LABEL_ABORT = "Abbrechen"

const MSG_USERNAME_NOT_AVAILABLE = "Der Benutzername ist nicht verfügbar.";
const MSG_TECHNICAL_PROBLEM = "Es ist ein technisches Problem aufgetreten.";

export default function EditUsernameDialog(props) {

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI;

    const [curValue, setCurValue] = useState(null)
    const [isAvailable, setIsAvailable] = useState(false)

    const onUsernameChanged = async (newUsername)  => {
        setCurValue(newUsername)
        try {
            const response = await fetch(buildUrl("authentication/isUsernameAvailable?username=" + contextAPI.username + "&password=" + contextAPI.password + "&newUsername=" + newUsername))
            if(response.status === HTTP_STATUS_SUCCESS) {
                const isAvailable = await response.json();
                if(!isAvailable) {
                    appAPI.showMessage(MSG_USERNAME_NOT_AVAILABLE);  
                }
                setIsAvailable(isAvailable);
            }    
            else {
                appAPI.showMessage(MSG_TECHNICAL_PROBLEM);  
            }
        } catch(e) {    
            appAPI.showMessage(e.toString());  
        } 
    }
   
    return( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField value={toEmptyStringIfNull(curValue)} onChange={(e) => onUsernameChanged(e.target.value)} id="name" variant="standard" fullWidth autoFocus/>
            </DialogContent>
            <DialogActions> 
                <Button onClick={() => {props.onClose(); setCurValue(null)}}>{LABEL_ABORT}</Button>
                <Button onClick={() => {props.onSave(curValue); setCurValue(null)}} disabled={!isAvailable}>{LABEL_SAVE}</Button>
            </DialogActions>
        </Dialog> 
    )  
}     