import { useEffect, useState } from "react";
import { fetchAuctionsAPI } from "../api/auction_api";
import { handleResponse } from "../Utility";
import { Box, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import Auction from "../fragments/Auction";
import RefreshButton from "../components/RefreshButton";
import { fetchBillableItemsAPI } from "../api/invoice_api";
import { Invoicing } from "../fragments/Invoice";


const LABEL_SEARCH = "Suchen";


const MSG_NO_AUCTIONS = "Keine verfügbaren Auktionen";

 
export default function Invoices(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const [updateBillableItems, setUpdateBillableItems] = useState(true);
    const [billableItemsList, setBillableItemsList] = useState([])
    const [maxCount, setMaxCount] = useState(3);


    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        const doUpdateBillableItems = async () => {
            handleResponse(fetchBillableItemsAPI(contextAPI.username, contextAPI.password), (response) => {
                setBillableItemsList(response);
                setUpdateBillableItems(false)
            }, appAPI.showMessage)
        }

        if(updateBillableItems) {
            doUpdateBillableItems();
        }
    })

    window.onwheel = () => setMaxCount(maxCount+2);
    window.ontouchmove = () => setMaxCount(maxCount + 1);
    
    let key = 0; 

    return ( 
        <Box style={{marginTop: "50px"}}>
            <Grid container>
                <Grid xs={12} item>
                    <TextField value={searchTerm} label="ID" variant="standard" onChange={(e) => {
                        const value = e.target.value;
                        if(/^-?\d+$/.test(value) || value == "") {
                            setSearchTerm(value)
                        }
                    }} fullWidth/>
                </Grid> 
                { 
                    billableItemsList.filter((item) => searchTerm == "" || (item.id != null && item.id.toString().includes(searchTerm))).slice(0, maxCount).map((item) => {
                        return(
                            <Grid xs={12} key={key++} item>
                                <Box style={{height: "50px"}}/>
                                <Invoicing
                                    appAPI={appAPI}
                                    contextAPI={contextAPI}
                                    billableItem={item}
                                /> 
                            </Grid>
                        ) 
                    })
                }
            </Grid>
        </Box>
    );
} 
  