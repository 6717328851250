import { buildUrl, formatParam } from "../Utility";

export const fetchAuctionsAPI = (username, password) => {
    return fetch(buildUrl("auction/all?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" });
}


export const fetchAuctionAPI = (username, password, auctionId) => {
    return fetch(buildUrl("auction/" + formatParam(auctionId) + "?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" });
}

export const fetchAuctionStatusAPI = (username, password, auctionId) => {
    return fetch(buildUrl("auction/status?username=" + formatParam(username) + "&password=" + formatParam(password) + "&auctionId=" + formatParam(auctionId)), { method: "GET" })
}

export const bidAPI = (username, password, auctionId, bid) => {
    return fetch(buildUrl("auction/bid?username=" + formatParam(username) + "&password=" + formatParam(password) + "&auctionId=" + formatParam(auctionId) + "&bid=" + formatParam(bid)), { method: "PUT" });
}

