import { HTTP_STATUS_SUCCESS, buildUrl, formatParam } from "../App";
import { MSG_CHANGES_SAVED, MSG_ERROR_OCCURED } from "../Utility";


const onSave = async (context, leadId, paramName, newParamValueName, paramValue, onChange, showMessage, then) => {
    try {
        const response = await fetch(buildUrl("lead/" + formatParam(leadId) + "/" + paramName + "?username=" + formatParam(context.username) + "&password=" + formatParam(context.password) + "&" + newParamValueName + "=" + formatParam(paramValue)), { method: "PUT" })
        if (response.status === HTTP_STATUS_SUCCESS) {
            onChange();
            showMessage(MSG_CHANGES_SAVED);
        }
        else {
            showMessage(MSG_ERROR_OCCURED + response.status)
        }
    } catch (e) {
        showMessage(e.toString());
    }
    then();
} 

export const onSaveFirm = (context, leadId, newFirmValue, onChange, showMessage, then) => {
    return onSave(context, leadId, "firm", "newFirm", newFirmValue, onChange, showMessage, then)
}

export const onSaveFirstName = (context, leadId, newFirm, onChange, showMessage, then) => {
    onSave(context, leadId, "firstName", "newFirstName", newFirm, onChange, showMessage, then)
}

export const onSaveLastName = (context, leadId, newFirm, onChange, showMessage, then) => {
    onSave(context, leadId, "lastName", "newLastName", newFirm, onChange, showMessage, then)
}

export const onSavePhoneNumber = (context, leadId, newPhoneNumber, onChange, showMessage, then) => {
    onSave(context, leadId, "phoneNumber", "newPhoneNumber", newPhoneNumber, onChange, showMessage, then)
}

export const onSaveEmailAddress = (context, leadId, newEmailAddress, onChange, showMessage, then) => {
    onSave(context, leadId, "emailAddress", "newEmailAddress", newEmailAddress, onChange, showMessage, then)
}

export const onSaveStreet = (context, leadId, newStreet, onChange, showMessage, then) => {
    onSave(context, leadId, "street", "newStreet", newStreet, onChange, showMessage, then)
}

export const onSaveHouseNo = (context, leadId, houseNo, onChange, showMessage, then) => {
    onSave(leadId, "houseNo", "newHouseNo", houseNo, onChange, showMessage, then)
}

export const onSaveZipCode = (context, leadId, zipCode, onChange, showMessage, then) => {
    onSave(context, leadId, "zipCode", "newZipCode", zipCode, onChange, showMessage, then)
}

export const onSaveTown = (context, leadId, town, onChange, showMessage, then) => {
    onSave(context, leadId, "town", "newTown", town, onChange, showMessage, then)
}

export const onSaveCountry = (context, leadId, country, onChange, showMessage, then) => {
    onSave(context, leadId, "country", "country", country, onChange, showMessage, then)
} 

export const onSavePublicComment = (context, leadId, publicComment, onChange, showMessage, then) => {
    onSave(context, leadId, "publicComment", "newPublicComment", publicComment, onChange, showMessage, then)
} 

export const onSaveBrokerComment = (context, leadId, brokerComment, onChange, showMessage, then) => {
    onSave(context, leadId, "brokerComment", "newBrokerComment", brokerComment, onChange, showMessage, then)
} 

export const onSaveCallAgentComment = (context, leadId, callAgentComment, onChange, showMessage, then) => {
    onSave(context, leadId, "callAgentComment", "newCallAgentComment", callAgentComment, onChange, showMessage, then)
} 

export const fetchLeadAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "?username="+ formatParam(username) + "&password=" + formatParam(password)) , {method: "GET"})
}   

export const fetchLeadByConfirmationCodeAPI = (leadId, confirmationCode) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/byConfirmationCode?confirmationCode="+ formatParam(confirmationCode)) , {method: "GET"})
}   



export const reissueProductInvoiceAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/productInvoice/reissue?username="+ formatParam(username) + "&password=" + formatParam(password)) , {method: "PUT"})
} 


export const reissueTransactionInvoiceAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/transactionInvoice/reissue?username="+ formatParam(username) + "&password=" + formatParam(password)) , {method: "PUT"})
} 



export const fetchPurchasesAPI = (username, password, searchTerm) => {
    let searchTermQuery = searchTerm == null || searchTerm == "" ? "" : ("&searchTerm=" + formatParam(searchTerm));
    return fetch(buildUrl("lead/purchased?username=" + formatParam(username) + "&password=" + formatParam(password) + searchTermQuery) , {method: "GET"})
}  

export const fetchCreatedAPI = (username, password, searchTerm) => {
    let searchTermQuery = searchTerm == null || searchTerm == "" ? "" : ("&searchTerm=" + formatParam(searchTerm));
    return fetch(buildUrl("lead/created?username=" + formatParam(username) + "&password=" + formatParam(password) + searchTermQuery) , {method: "GET"})
}  

export const fetchNotesAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/notes?username=" + formatParam(username) + "&password=" + formatParam(password), {method: "GET"}))
} 

export const addNoteAPI = (username, password, leadId, noteStr) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/notes?username=" + formatParam(username) + "&password=" + formatParam(password) + "&noteStr=" + formatParam(noteStr)), {method: "PUT"})
} 
    
export const fetchAssessmentsAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/assessments?username=" + formatParam(username) + "&password=" + formatParam(password), {method: "GET"}))
} 

export const assessLeadAPI = (username, password, leadId, assessmentCategoryId, assessmentValue) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/assessments?username=" + formatParam(username) + "&password=" + formatParam(password) + "&assessmentCategoryId=" + formatParam(assessmentCategoryId) + "&assessmentValue=" + formatParam(assessmentValue)), {method: "PUT"})
} 

export const isBudgetAvailableAPI = (username, password, price) => {
    return fetch(buildUrl("lead/isBudgetAvailable?username=" + formatParam(username)  + "&password=" + formatParam(password + "&price=" + formatParam(price))))
} 

export const confirmLeadAPI = (leadId, confirmationCode) => {
    return fetch(buildUrl("lead/" + formatParam(leadId)  + "/confirm?confirmationCode=" + formatParam(confirmationCode)), {method: "PUT"})
} 

export const makeClaimAPI = (username, password, leadId, claimStr) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/makeClaim?username=" + formatParam(username) + "&password=" + formatParam(password)+ "&claimString=" + formatParam(claimStr)), {method: "PUT"})
} 

export const handleClaimAPI = (username, password, leadId, accept) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/handleClaim?username=" + formatParam(username) + "&password=" + formatParam(password)+ "&accept=" + formatParam(accept)), {method: "PUT"})
} 


export const assignAPI = (
    username,  
    password, 
    firm,
    firstName, 
    lastName, 
    birthDate, 
    phoneNumber,  
    emailAddress, 
    street, 
    houseNo, 
    zipCode, 
    town, 
    country, 
    categoryId, 
    comment, 
    hasDeclaredConsent, 
    mail, 
    phone
) => {
    return fetch(buildUrl("lead/newPurchase?username=" + formatParam(username) 
    + "&password=" + formatParam(password) 
    + "&firm=" + formatParam(firm)
    + "&firstName=" + formatParam(firstName) 
    + "&lastName=" + formatParam(lastName)
    + "&birthDate=" + formatParam(birthDate)
    + "&phoneNumber=" + formatParam(phoneNumber) 
    + "&emailAddress=" + formatParam(emailAddress)
    + "&street=" + formatParam(street)
    + "&houseNo=" + formatParam(houseNo)
    + "&zipCode=" + formatParam(zipCode) 
    + "&town=" + formatParam(town)
    + "&country=" + formatParam(country)
    + "&categoryId=" + formatParam(categoryId)
    + "&comment=" + formatParam(comment)
    + "&hasDeclaredConsent=" + formatParam(hasDeclaredConsent)
    + "&mail=" + formatParam(mail)
    + "&phone=" + formatParam(phone))
    , {method: "PUT"});
}

export const createAuctionAPI = (
    username,  
    password, 
    firm,
    firstName, 
    lastName, 
    birthDate, 
    phoneNumber,  
    emailAddress, 
    street, 
    houseNo, 
    zipCode, 
    town, 
    country, 
    categoryId, 
    comment, 
    minBid,
    hasDeclaredConsent,
    mail, 
    phone, 
    paymentTerm
) => {
    return fetch(buildUrl("lead/newAuction?username=" + formatParam(username) 
    + "&password=" + formatParam(password) 
    + "&firm=" + formatParam(firm)
    + "&firstName=" + formatParam(firstName) 
    + "&lastName=" + formatParam(lastName)
    + "&birthDate=" + formatParam(birthDate)
    + "&phoneNumber=" + formatParam(phoneNumber) 
    + "&emailAddress=" + formatParam(emailAddress)
    + "&street=" + formatParam(street)
    + "&houseNo=" + formatParam(houseNo)
    + "&zipCode=" + formatParam(zipCode) 
    + "&town=" + formatParam(town)
    + "&country=" + formatParam(country)
    + "&categoryId=" + formatParam(categoryId)
    + "&comment=" + formatParam(comment)
    + "&minBid=" + formatParam(minBid)
    + "&hasDeclaredConsent=" + formatParam(hasDeclaredConsent)
    + "&mail=" + formatParam(mail)
    + "&phone=" + formatParam(phone)
    + "&paymentTerm=" + formatParam(paymentTerm))
    , {method: "PUT"});
}

export const createOfferAPI = (
    username,  
    password, 
    firm,
    firstName, 
    lastName, 
    birthDate, 
    phoneNumber,  
    emailAddress, 
    street, 
    houseNo, 
    zipCode, 
    town, 
    country, 
    categoryId, 
    comment, 
    price,
    hasDeclaredConsent,
    mail, 
    phone,
    paymentTerm
) => {
    return fetch(buildUrl("lead/newOffer?username=" + formatParam(username) 
    + "&password=" + formatParam(password) 
    + "&firm=" + formatParam(firm)
    + "&firstName=" + formatParam(firstName) 
    + "&lastName=" + formatParam(lastName)
    + "&birthDate=" + formatParam(birthDate)
    + "&phoneNumber=" + formatParam(phoneNumber) 
    + "&emailAddress=" + formatParam(emailAddress)
    + "&street=" + formatParam(street)
    + "&houseNo=" + formatParam(houseNo)
    + "&zipCode=" + formatParam(zipCode) 
    + "&town=" + formatParam(town)
    + "&country=" + formatParam(country)
    + "&categoryId=" + formatParam(categoryId)
    + "&comment=" + formatParam(comment)
    + "&price=" + formatParam(price)
    + "&hasDeclaredConsent=" + formatParam(hasDeclaredConsent)
    + "&mail=" + formatParam(mail)
    + "&phone=" + formatParam(phone)
    + "&paymentTerm=" + formatParam(paymentTerm))
    , {method: "PUT"});
} 

export const recreateOfferAPI = (
    username,  
    password, 
    id,
    price
) => {
    return fetch(buildUrl("lead/" + formatParam(id) + "/recreateOffer?username=" + formatParam(username) 
    + "&password=" + formatParam(password) 
    + "&price=" + formatParam(price))
    , {method: "PUT"});
} 

export const recreateAuctionAPI = (
    username,  
    password, 
    id,
    minBid
) => {
    return fetch(buildUrl("lead/" + formatParam(id) + "/recreateAuction?username=" + formatParam(username) 
    + "&password=" + formatParam(password) 
    + "&minBid=" + formatParam(minBid))
    , {method: "PUT"});
}   

export const fetchSalesStatusAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/salesStatus?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
}   

export const abortSaleAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/abort?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "PUT" })
}     

export const proposePriceAPI = (username, password, leadId, price) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/price?username=" + formatParam(username) + "&password=" + formatParam(password) + "&price=" + formatParam(price)), { method: "PUT" })
}     


export const acceptPriceAPI = (username, password, leadId, price) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/price/" + formatParam(price) + "?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "PUT" })
}     


export const proposeCancellationAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/cancellation?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "PUT" })
}     

export const approveCancellationAPI = (username, password, leadId, accept) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/cancellation/approval?username=" + formatParam(username) + "&password=" + formatParam(password) + "&accept=" + formatParam(accept)), { method: "PUT" })
}    

export const findProductInvoicesAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/product_invoices?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
}     

export const findTransactionInvoicesAPI = (username, password, leadId) => {
    return fetch(buildUrl("lead/" + formatParam(leadId) + "/transaction_invoices?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
}     

