import { useEffect, useState } from "react";

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";

import { HTTP_STATUS_SUCCESS, PRIMARY_COLOR, SECONDARY_COLOR, buildUrl, formatParam } from './../App';
import { MSG_ERROR_OCCURED } from "../Utility";

import { createUserAPI, fetchUsersAPI } from "../api/user_api";
import User from "../fragments/User";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RefreshButton from "../components/RefreshButton";

const LABEL_FIRST_NAME = "Vorname"
const LABEL_LAST_NAME = "Nachname"
const LABEL_EMAIL_ADDRESS = "Emailaddresse"
const LABEL_USERNAME = "Benutzername"
const LABEL_PASSWORD = "Passwort"
const LABEL_REPEAT_PASSWORD = "Passwort wiederholen"
const LABEL_ADMIN = "Administrator"

const LABEL_IS_SUBSCRIBER = "Abonnent"
const LABEL_IS_PURCHASER = "Käufer"
const LABEL_SELLER = "Verkäufer"

const LABEL_ADD_USER = "Benutzer hinzufügen"
const LABEL_CANCEL = "Abbrechen"

const MSG_CHANGES_SAVED = "Änderungen gespeichert."


export default function Users(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const [userIdList, setUserIdList] = useState([]);
    const [updateUserList, setUpdateUserList] = useState(true);

    const [maxCount, setMaxCount] = useState(3);

    useEffect(() => {

        const doUpdateUserList = async () => {
            try {
                const response = await fetchUsersAPI(contextAPI.username, contextAPI.password);
                if (response.status === HTTP_STATUS_SUCCESS) {
                    setUserIdList(await response.json());
                }
                else {
                    appAPI.showMessage(MSG_ERROR_OCCURED + " " + response.status);
                }
            } catch (e) {
                appAPI.showMessage(e.toString());
            }
            setUpdateUserList(false);
        }


        if (updateUserList) {
            doUpdateUserList()
            setUpdateUserList(false);
        }
    })

    window.onwheel = () => setMaxCount(maxCount + 1)
    window.ontouchmove = () => setMaxCount(maxCount + 1);

    let count = 0;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box style={{ height: "50px" }} />
            </Grid>
            <Grid item xs={12}>
                <AddUser
                    appAPI={appAPI}
                    contextAPI={contextAPI}
                    reload={() => setUpdateUserList(true)}
                />
            </Grid>

            {
                userIdList.map((userId) => {
                    count += 1
                    return count <= maxCount ?
                        <Grid item xs={12}>
                            <User
                                key={userId}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                userId={userId}
                            />
                        </Grid>
                        :
                        null
                })
            }
        </Grid>
    ) 
}

function AddUser(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [repeatedPassword, setRepeatedPassword] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const [isSubscriber, setIsSubscriber] = useState(false);
    const [isPurchaser, setIsPurchaser] = useState(false);
    const [isCallAgent, setIsCallAgent] = useState(true);
    const [open, setOpen] = useState(false);

    const createUser = async () => {
        try {
            const response = await createUserAPI(contextAPI.username, contextAPI.password, username, password, emailAddress, firstName, lastName, isAdmin, isCallAgent, isSubscriber, isPurchaser);
            if (response.status === HTTP_STATUS_SUCCESS) {
                appAPI.showMessage(MSG_CHANGES_SAVED);
            }
            else {
                appAPI.showMessage(MSG_ERROR_OCCURED + " " + response.status);
            }
        } catch (e) {
            appAPI.showMessage(e.toString());
        }
        setFirstName(null);
        setLastName(null);
        setEmailAddress(null);
        setUsername(null);
        setPassword(null);
        setRepeatedPassword(null);
        setIsAdmin(null);
        setIsPurchaser(null);
        setIsSubscriber(null);
        setIsCallAgent(null);
        props.reload();
        setOpen(false);
    }


    return (
        <Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{LABEL_ADD_USER}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={LABEL_FIRST_NAME}
                                value={firstName == null ? "" : firstName}
                                onChange={(e) => { setFirstName(e.target.value) }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={LABEL_LAST_NAME}
                                value={lastName == null ? "" : lastName}
                                onChange={(e) => { setLastName(e.target.value) }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={LABEL_EMAIL_ADDRESS}
                                value={emailAddress == null ? "" : emailAddress}
                                onChange={(e) => { setEmailAddress(e.target.value) }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={LABEL_USERNAME}
                                value={username == null ? "" : username}
                                onChange={(e) => { setUsername(e.target.value) }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={LABEL_PASSWORD}
                                value={password == null ? "" : password}
                                onChange={(e) => { setPassword(e.target.value) }}
                                variant="standard"
                                type="password"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={LABEL_REPEAT_PASSWORD}
                                value={repeatedPassword == null ? "" : repeatedPassword}
                                onChange={(e) => { setRepeatedPassword(e.target.value) }}
                                variant="standard"
                                type="password"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: "20px" }}>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={isAdmin} />}
                                label={LABEL_ADMIN}
                                onChange={(e) => { setIsAdmin(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={isSubscriber} />}
                                label={LABEL_IS_SUBSCRIBER}
                                onChange={(e) => { setIsSubscriber(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={isPurchaser} />}
                                label={LABEL_IS_PURCHASER}
                                onChange={(e) => { setIsPurchaser(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={isCallAgent} />}
                                label={LABEL_SELLER}
                                onChange={(e) => { setIsCallAgent(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{LABEL_CANCEL}</Button>
                    <Button onClick={createUser}>{LABEL_ADD_USER}</Button>
                </DialogActions>
            </Dialog>
            {
                contextAPI.admin ?
                    <Button
                        variant="contained"
                        onClick={() => setOpen(true)}
                        style={{ backgroundColor: SECONDARY_COLOR, color: "white", minWidth: "150px", marginBottom: "50px" }}
                        startIcon={<AddIcon />}
                    >
                        {LABEL_ADD_USER}
                    </Button>
                    :
                    null
            }
        </Box>
    )
}
