import { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Grid, LinearProgress, TextField, Typography } from '@mui/material';


import Offer from '../fragments/Offer';
import { fetchOffersAPI } from '../api/offer_api';
import { handleResponse } from '../Utility';
import RefreshButton from '../components/RefreshButton';

const MSG_NO_OFFERS = "Keine verfügbaren Angebote";

export default function Offers(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const [offerList, setOfferList] = useState([]);
    const [updateOfferList, setUpdateOfferList] = useState(true);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (updateOfferList) {
            handleResponse(fetchOffersAPI(contextAPI.username, contextAPI.password), (response) => {setOfferList(response); setUpdateOfferList(false);}, appAPI.showMessage);
        }
    })

    return ( 
        <Box>
            {
                updateOfferList ?
                    <LinearProgress />
                    :
                    (

                        <Grid container>

                            <Grid item xs={12}>
                                <Box style={{height: "50px"}}/>
                                {
                                    offerList.length > 0 ?
                                        offerList.map((offerId) => (
                                            <Offer 
                                                appAPI = {props.appAPI}
                                                contextAPI = {props.contextAPI}
                                                offerId={offerId}
                                                reload={() => setUpdateOfferList(true) }
                                                key={offerId}
                                            />
                                            )
                                        )
                                        :
                                        <Typography variant='p'>{MSG_NO_OFFERS}</Typography>
                                }
                            </Grid>
                        </Grid>
                    )
            } 
        </Box>

    )
}


/*
                            <Grid item xs={12}>
                                <TextField
                                    value={searchTerm}
                                    label={"Suche"}
                                    variant="standard"
                                    onChange={(e) => {setSearchTerm(e.target.value)}}
                                    fullWidth
                                    disabled={offerList.length == 0}
                                />
                            </Grid> 
*/


