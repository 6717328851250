import { useEffect, useState } from "react";
import { handleResponse } from "../Utility";
import { addNote, fetchLeads, fetchNotes, fetchPurchasesAPI } from "../api/lead_api";
import { Box, Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import Lead from "../fragments/Lead";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../App";
import { fetchPurchasesCsvAPI } from "../api/reporting_api";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RefreshButton from "../components/RefreshButton";


const LABEL_SEARCH = "Suche"

const MSG_NO_PURCHAES = "Keine Ergebnisse"

export default function Purchases(props) {

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI

    const [purchaseList, setPurchaseList] = useState([]);
    const [updatePurchaseList, setUpdatePurchaseList] = useState(true);

    const [maxCount, setMaxCount] = useState(3);
    const [searchTerm, setSearchTerm] = useState("");


    useEffect(() => {
        if (updatePurchaseList) {
            handleResponse(fetchPurchasesAPI(contextAPI.username, contextAPI.password, searchTerm), (response) => { setPurchaseList(response); setUpdatePurchaseList(false); }, appAPI.showMessage);
        }
    })

    const downloadPurchases = () => {
        handleResponse(fetchPurchasesCsvAPI(contextAPI.username, contextAPI.password), (response) => {
            var csvContent = "data:text/csv;charset=utf-8," + response;
            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }, appAPI.showMessage)
    }

    window.onwheel = () => setMaxCount(maxCount + 2)
    window.ontouchmove = () => setMaxCount(maxCount + 1);

    let count = 0;

    return (
        updatePurchaseList ?
            <LinearProgress />
            :
            <Grid container>
                <Grid item xs={12}><Box style={{ height: "50px" }} /></Grid>
                <Grid item xs={12}>
                    <Button onClick={downloadPurchases} variant="contained" startIcon={<CloudDownloadIcon />} style={{ backgroundColor: SECONDARY_COLOR, marginRight: "25px" }}>
                        Export
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ height: "50px" }} />
                    {
                        purchaseList.length > 0 ?
                            purchaseList.map((leadId) => {
                                count += 1;
                                return (
                                    count <= maxCount ?
                                        <Lead
                                            key={leadId}
                                            appAPI={appAPI}
                                            contextAPI={contextAPI}
                                            leadId={leadId}
                                            buyerView={true}
                                        />
                                        :
                                        null
                                )
                            })
                            :
                            <Typography variant='p'>{MSG_NO_PURCHAES}</Typography>
                    }
                </Grid>
            </Grid>
    )
}

