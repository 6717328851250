import { Box, TextField, Typography } from "@mui/material";
import { PRIMARY_COLOR } from "../App";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchUserImprintAPI } from "../api/user_api";
import { handleResponse, toEmptyStringIfNull } from "../Utility";

const HEADING_USER = "Benutzer"
const HEADING_IMPRINT = "Impressum"

export default function UserImprint(props) {

    const { userId } = useParams();

    const appAPI = props.appAPI;

    const [update, setUpdate] = useState(true);
    const [imprint, setImprint] = useState("");

    useEffect(() => {
        if(update) {
            handleResponse(fetchUserImprintAPI(userId), (response) => {
                setImprint(toEmptyStringIfNull(response).replaceAll("$", "\n"))
            }, appAPI.showMessage)
            setUpdate(false);
        }
    })

    return( 
        <Box>
            <Typography variant="h2" style={{color: PRIMARY_COLOR}}>
                {HEADING_USER}-
            </Typography>
            <Typography variant="h1" style={{color: PRIMARY_COLOR}}>
                {HEADING_IMPRINT}
            </Typography>
            <TextField 
                multiline
                disabled
                fullWidth
                variant="standard"
                value={imprint}
                style={{marginTop: "50px"}}
            />
        </Box>
    )
} 