import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { buildUrl, formatParam, HTTP_STATUS_SUCCESS, PRIMARY_COLOR, SECONDARY_COLOR } from './../App';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import React from 'react'
import { LABEL_ZIP_CODE, TabPanel, a11yProps, handleResponse } from '../Utility';
import { buyOfferAPI, fetchOfferAPI } from '../api/offer_api';
import { SellerReview } from './Review';
import { fetchUserCanBuylAPI } from '../api/user_api';

const LABEL_CATEGGORY = "Kategorie";
const LABEL_BUY_NOW = "Jetzt kaufen";
const LABEL_DETAILS = "Details";
const LABEL_SELLER_DETAILS = "Anbieter";
const MSG_SUCCESSFULL_PURCHASE = "Der Kauf wurde erfolgreich abgeschlossen.";
const MSG_FAILED_PURCHASE = "Der Kauf wurde nicht erfolgreich abgeschlossen.";
const LABEL_B2B = "B2B"
const LABEL_B2C = "B2C"
const LABEL_ITEM_TYPE = ""
const LABEL_YEAR_OF_BIRTH = "Geburtsjahr"
const LABLE_CURRENCY = "EUR"
const LABEL_PRICE = "Preis"
const LABEL_COMMENT = "Kommentar"
const LABEL_VAT_RATE = "Umsatzsteuersatz"
const LABEL_PAYMENT_TERM = "Zahlungsziel"

export default function Offer(props) {


    let appAPI = props.appAPI;
    let contextAPI = props.contextAPI;

    let offerId = props.offerId;

    const [tabIndex, setTabIndex] = useState(0);

    const [offer, setOffer] = useState(null);
    const [bought, setBought] = useState(false);
    const [updateOffer, setUpdateOffer] = useState(true);

    const [canBuy, setCanBuy] = useState(false);
    const [updateCanBuy, setUpdateCanBuy] = useState(true);



    useEffect(() => {
        if (updateOffer) {
            handleResponse(fetchOfferAPI(contextAPI.username, contextAPI.password, offerId), (response) => { setOffer(response); }, appAPI.showMessage);
            setUpdateOffer(false)
        }
        if (updateCanBuy) {
            handleResponse(fetchUserCanBuylAPI(contextAPI.id, contextAPI.username, contextAPI.password), (response) => { setCanBuy(response); setUpdateCanBuy(false); }, appAPI.showMessage);
        }
    })


    const buyOffer = () => {
        handleResponse(buyOfferAPI(contextAPI.username, contextAPI.password, offerId), async (success) => {
            if (success) {
                props.reload();
                appAPI.showMessage(MSG_SUCCESSFULL_PURCHASE);
                setBought(true);
            }
            else {
                props.reload();
                appAPI.showMessage(MSG_FAILED_PURCHASE);
            }
        }, appAPI.showMessage);
    }

    return (
        offer != null ?
            <Accordion style={{ marginBottom: "50px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "white" }} />} style={{ backgroundColor: PRIMARY_COLOR, color: "white" }}>
                    <Typography variant="span" style={{ fontFamily: "Arial, Helvetica" }}>
                        {LABEL_CATEGGORY + ": " + offer.categoryName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                    <TabPanel value={tabIndex} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    value={offer.b2b ? LABEL_B2B : LABEL_B2B}
                                    label={LABEL_ITEM_TYPE}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={offer.zipCode}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={offer.birthdate}
                                    label={LABEL_YEAR_OF_BIRTH}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            {
                                offer.price == null ?
                                    null
                                    :
                                    (
                                        <Grid item xs={12}>
                                            <TextField
                                                value={offer.price + " " + LABLE_CURRENCY}
                                                label={LABEL_PRICE}
                                                variant="standard"
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )
                            }
                            <Grid item xs={6}>
                                <TextField
                                    value={offer.paymentTerm + " Tage"}
                                    label={LABEL_PAYMENT_TERM}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={offer.vatRate + " %"}
                                    label={LABEL_VAT_RATE}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            {
                                bought ?
                                    null
                                    :
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={!canBuy}
                                            variant="contained"
                                            style={{ whiteSpace: "pre-wrap", backgroundColor: SECONDARY_COLOR, color: "white", minTop: "50px", minWidth: "150px" }}
                                            onClick={() => { buyOffer(offer.offerId) }}
                                        >
                                            {LABEL_BUY_NOW}
                                        </Button>
                                    </Grid>
                            }

                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    value={offer.comment != null ? offer.comment.replaceAll("$", "\n") : ""}
                                    label={LABEL_COMMENT}
                                    variant="standard"
                                    fullWidth
                                    multiline
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <SellerReview
                            appAPI={appAPI}
                            contextAPI={contextAPI}
                            userId={offer.sellerUserId}
                        />
                    </TabPanel>
                    <Divider />
                    <Tabs value={tabIndex} onChange={(event, newValue) => { setTabIndex(newValue) }}>
                        <Tab label={LABEL_DETAILS} {...a11yProps(0)} />
                        <Tab label={LABEL_COMMENT} {...a11yProps(1)} />
                        <Tab label={LABEL_SELLER_DETAILS} {...a11yProps(2)} />
                    </Tabs>
                </AccordionDetails>
            </Accordion>
            :
            <Typography>Nicht verfügbar</Typography>
    )
}