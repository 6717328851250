import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, FormGroup, Tab, Tabs, Typography } from "@mui/material";

import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import EditTextDialog from '../components/EditTextDialog';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../App';
import EditPasswordDialog from '../components/EditPasswordDialog';
import EditUsernameDialog from '../components/EditUsernameDialog';
import { LABEL_IMPRINT, LABEL_SAVE, LABEL_DISABLE, toEmptyStringIfNull, MSG_CHANGES_NOT_SAVED, a11yProps, TabPanel, MSG_CHANGES_SAVED, handleResponse, handleValidationResponse } from '../Utility';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import NoteIcon from '@mui/icons-material/Note';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { SellerReview } from "./Review";
import { disableAPI, fetchUserAPI, saveBankAPI, saveBICAPI, saveCompanyRegistrationAPI, saveCountryAPI, saveEmailAddressAPI, saveFirmAPI, saveHouseNoAPI, saveIBANAPI, saveImprintAPI, saveIsAdminAPI, saveIsCallAgentAPI, saveIsPurchaserAPI, saveIsSubscriberAPI, saveIsVatIdAPI, saveLastnameNameAPI, saveNonRegisteredSoleTraderAPI, savePasswordAPI, savePhoneNumberAPI, savePortraitURLAPI, savePrenameNameAPI, saveStreetAPI, saveTaxExemptionAPI, saveTaxIdAPI, saveTownAPI, saveUsernameAPI, saveUstIdAPI, saveVatRateAPI, saveZipCodeAPI } from "../api/user_api";
import Budget from "./Budget";
import { validateAcceptAny, validateNonEmptyAlphanumericString, validateNonEmptyHouseNo, validatePossiblyEmptyAlphanumericString, validatePossiblyEmptyEmailAddress, validatePossiblyEmptyHouseNo, validatePossiblyEmptyPhoneNumber, validatePossiblyEmptyUstID, validatePossiblyEmptyZipCode } from "../api/validation_api";
import PartnerManagement from "./PartnerManagement";

const LABEL_FIRM = "Firma"
const LABEL_FIRST_NAME = "Vorname"
const LABEL_LAST_NAME = "Nachname"
const LABEL_USERNAME = "Benutzername"
const LABEL_PASSWORD = "Passwort"
const LABEL_STREET = "Straße"
const LABEL_HOUSE_NO = "Nr."
const LABEL_ZIP_CODE = "Postleitzahl"
const LABEL_TOWN = "Ort"
const LABEL_COUNTRY = "Land"
const LABEL_VAT_ID = "Ust-ID."



const LABEL_PHONE_NUMBER = "Handynummer"
const LABEL_EMAIL_ADDRESS = "Emailadresse"
const LABEL_PORTRAIT_URL = "Portrait-URL"
const LABEL_EDIT_FIRST_NAME = "Vorname ändern"
const LABEL_EDIT_LAST_NAME = "Nachname ändern"
const LABEL_EDIT_PHONE_NUMBER = "Handynummer ändern"
const LABEL_EDIT_EMAIL_ADDRESS = "Emailadresse ändern"
const LABEL_EDIT_PORTRAIT = "Porträt ändern"
const LABEL_EDIT_USERNAME = "Benutzername ändern"
const LABEL_EDIT_PASSWORD = "Passwort ändern"
const LABEL_ADMIN = "Administrator"
const LABEL_IS_SUBSCRIBER = "Abonnent"
const LABEL_IS_PURCHASER = "Käufer"
const LABEL_SELLER = "Verkäufer"

const LABEL_EDIT_FIRM = "Firma ändern"
const LABEL_EDIT_STREET = "Straße ändern"
const LABEL_EDIT_HOUSE_NO = "Hausnummer ändern"
const LABEL_EDIT_ZIP_CODE = "Postleitzahl ändern"
const LABEL_EDIT_TOWN = "Ort ändern"
const LABEL_EDIT_VAT_ID = "Ust-ID. ändern"
const LABEL_EDIT_TAX_ID = "Steuernummer ändern"
const LABEL_EDIT_VAT_RATE = "Steuersatz ändern"
const LABEL_EDIT_BANK = "Bank ändern"
const LABEL_EDIT_BIC = "BIC ändern"
const LABEL_EDIT_IBAN = "IBAN ändern"

const LABEL_EDIT_COUNTRY = "Land ändern"


const LABEL_PERSON = "Person"
const LABEL_LOGIN = "Login"
const LABEL_ROLES = "Rollen"
const LABEL_REVIEWS = "Bewertungen"
const LABEL_PARTNER_MANAGEMENT = "Partnermanagement"
const LABEL_BUDGET = "Budget"

export default function User(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const userId = props.userId;

    //GUI
    const [tabIndex, setTabIndex] = useState(0)
    const [editFirm, setEditFirm] = useState(false)
    const [editFirstName, setEditFirstName] = useState(false)
    const [editLastName, setEditLastName] = useState(false)
    const [editStreet, setEditStreet] = useState(false)
    const [editHouseNo, setEditHoueNo] = useState(false)
    const [editZipCode, setEditZipCode] = useState(false)
    const [editTown, setEditTown] = useState(false)
    const [editCountry, setEditCountry] = useState(false)
    const [editPortraitURL, setEditPortraitURL] = useState(false)

    const [editVatId, setEditVatId] = useState(false)
    const [editTaxId, setEditTaxId] = useState(false)
    const [editVatRate, setEditVatRate] = useState(false)
    const [editBank, setEditBank] = useState(false)
    const [editBIC, setEditBIC] = useState(false)
    const [editIBAN, setEditIBAN] = useState(false)


    const [editPhoneNumber, setEditPhoneNumber] = useState(false)
    const [editEmailAddress, setEditEmailAddress] = useState(false)
    const [editUsername, setEditUsername] = useState(false)
    const [editPassword, setEditPassword] = useState(false)
    //Data
    const [user, setUser] = useState(true)
    const [imprint, setImprint] = useState(null)
    const [updateUser, setUpdateUser] = useState(true)

    const [isValidImprint, setIsValidImprint] = useState(true)


    useEffect(() => {
        if (updateUser) {
            handleResponse(fetchUserAPI(userId, contextAPI.username, contextAPI.password), (response) => { setUser(response); setImprint(response.imprint); setUpdateUser(false) }, appAPI.showMessage)
        }
    })

    const saveAndLogout = () => {
        setUpdateUser(true);
        appAPI.showMessage(MSG_CHANGES_SAVED);
        if (contextAPI.id == userId) {
            contextAPI.logout()
        }
    }

    const saveAndUpdate = () => {
        setUpdateUser(true);
        appAPI.showMessage(MSG_CHANGES_SAVED);
        if (contextAPI.id == userId) {
            contextAPI.update()
        }
    }

    const preprocessImprint = (imprint) => {
        if (imprint !== null) {
            imprint = imprint.replaceAll("\n", "$")
        }
        return imprint;
    }

    const saveUsername = async (newUsername) => handleResponse(saveUsernameAPI(userId, contextAPI.username, contextAPI.password, newUsername), (response) => saveAndLogout(), appAPI.showMessage)

    const savePassword = async (newPassword) => handleResponse(savePasswordAPI(userId, contextAPI.username, contextAPI.password, newPassword), (response) => saveAndLogout(), appAPI.showMessage)

    const saveFirm = async (newFirm) => handleResponse(saveFirmAPI(userId, contextAPI.username, contextAPI.password, newFirm), (response) => saveAndUpdate(), appAPI.showMessage)

    const savePrename = async (newPrename) => handleResponse(savePrenameNameAPI(userId, contextAPI.username, contextAPI.password, newPrename), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveLastname = async (newLastName) => handleResponse(saveLastnameNameAPI(userId, contextAPI.username, contextAPI.password, newLastName), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveStreet = async (newValue) => handleResponse(saveStreetAPI(userId, contextAPI.username, contextAPI.password, newValue), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveHouseNo = async (newValue) => handleResponse(saveHouseNoAPI(userId, contextAPI.username, contextAPI.password, newValue), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveZipCode = async (newValue) => handleResponse(saveZipCodeAPI(userId, contextAPI.username, contextAPI.password, newValue), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveTown = async (newValue) => handleResponse(saveTownAPI(userId, contextAPI.username, contextAPI.password, newValue), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveCountry = async (newCountry) => handleResponse(saveCountryAPI(userId, contextAPI.username, contextAPI.password, newCountry), (response) => saveAndUpdate(), appAPI.showMessage)

    const savePhoneNumber = async (newPhoneNumber) => handleResponse(savePhoneNumberAPI(userId, contextAPI.username, contextAPI.password, newPhoneNumber), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveEmailAddress = async (newEmailAddress) => handleResponse(saveEmailAddressAPI(userId, contextAPI.username, contextAPI.password, newEmailAddress), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveImprint = async (imprint) => handleResponse(saveImprintAPI(userId, contextAPI.username, contextAPI.password, preprocessImprint(imprint)), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveIsAdmin = async (isAdmin) => handleResponse(saveIsAdminAPI(userId, contextAPI.username, contextAPI.password, isAdmin), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveIsPurchaser = async (isPurchaser) => handleResponse(saveIsPurchaserAPI(userId, contextAPI.username, contextAPI.password, isPurchaser), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveIsSubscriber = async (isSubscriber) => handleResponse(saveIsSubscriberAPI(userId, contextAPI.username, contextAPI.password, isSubscriber), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveIsCallAgent = async (isCallAgent) => handleResponse(saveIsCallAgentAPI(userId, contextAPI.username, contextAPI.password, isCallAgent), (response) => saveAndUpdate(), appAPI.showMessage)

    const savePortraitURL = async (portraitURL) => handleResponse(savePortraitURLAPI(userId, contextAPI.username, contextAPI.password, portraitURL), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveVatID = async (vatID) => handleResponse(saveIsVatIdAPI(userId, contextAPI.username, contextAPI.password, vatID), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveTaxID = async (taxId) => handleResponse(saveTaxIdAPI(userId, contextAPI.username, contextAPI.password, taxId), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveVatRate = async (vatRate) => handleResponse(saveVatRateAPI(userId, contextAPI.username, contextAPI.password, vatRate), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveBank = async (bank) => handleResponse(saveBankAPI(userId, contextAPI.username, contextAPI.password, bank), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveBIC = async (bic) => handleResponse(saveBICAPI(userId, contextAPI.username, contextAPI.password, bic), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveIBAN = async (iban) => handleResponse(saveIBANAPI(userId, contextAPI.username, contextAPI.password, iban), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveTaxExemption = async (taxExemption) => handleResponse(saveTaxExemptionAPI(userId, contextAPI.username, contextAPI.password, taxExemption), (response) => saveAndUpdate(), appAPI.showMessage)

    const saveNonRegisteredSoleTrader = async (nonRegistered) => handleResponse(saveNonRegisteredSoleTraderAPI(userId, contextAPI.username, contextAPI.password, nonRegistered), (response) => saveAndUpdate(), appAPI.showMessage)

    const disable = async () => handleResponse(disableAPI(userId, contextAPI.username, contextAPI.password), (response) => saveAndLogout(), appAPI.showMessage)

    const isEditable = contextAPI.admin || contextAPI.id === userId

    let nextTabIndex = 0;

    return (

        <Accordion style={{ marginBottom: "50px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "white" }} />} style={{ backgroundColor: user.disabled ? "gray" : PRIMARY_COLOR, color: "white" }} disabled={user.disabled}>
                <Typography component='span' variant="span" >{user.username} {user.firstName != null && user.lastName != null ? "(" + user.firstName + " " + user.lastName + ")" : ""}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px" }}>
                <TabPanel value={tabIndex} index={nextTabIndex++}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                value={user.firm == null ? "" : user.firm}
                                label={LABEL_FIRM}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditFirm(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyAlphanumericString} dialogTitle={LABEL_EDIT_FIRM} open={editFirm} onClose={() => setEditFirm(false)} onSave={(newValue) => { saveFirm(newValue); setEditFirm(false); }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={user.firstName == null ? "" : user.firstName}
                                label={LABEL_FIRST_NAME}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => setEditFirstName(true)} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validateNonEmptyAlphanumericString} dialogTitle={LABEL_EDIT_FIRST_NAME} open={editFirstName} onClose={() => setEditFirstName(false)} onSave={(firstName) => { savePrename(firstName); setEditFirstName(false) }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={user.lastName == null ? "" : user.lastName}
                                label={LABEL_LAST_NAME}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditLastName(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validateNonEmptyAlphanumericString} dialogTitle={LABEL_EDIT_LAST_NAME} open={editLastName} onClose={() => setEditLastName(false)} onSave={(newLastname) => { saveLastname(newLastname); setEditLastName(false); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={user.street == null ? "" : user.street}
                                label={LABEL_STREET}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditStreet(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyAlphanumericString} dialogTitle={LABEL_EDIT_STREET} open={editStreet} onClose={() => setEditStreet(false)} onSave={(newValue) => { saveStreet(newValue); setEditStreet(false); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={user.houseNo == null ? "" : user.houseNo}
                                label={LABEL_HOUSE_NO}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditHoueNo(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyHouseNo} dialogTitle={LABEL_HOUSE_NO} open={editHouseNo} onClose={() => setEditHoueNo(false)} onSave={(newValue) => { saveHouseNo(newValue); setEditHoueNo(false); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={user.zipCode == null ? "" : user.zipCode}
                                label={LABEL_ZIP_CODE}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditZipCode(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyZipCode} dialogTitle={LABEL_EDIT_ZIP_CODE} open={editZipCode} onClose={() => setEditZipCode(false)} onSave={(newValue) => { saveZipCode(newValue); setEditZipCode(false); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={user.town == null ? "" : user.town}
                                label={LABEL_TOWN}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditTown(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyAlphanumericString} dialogTitle={LABEL_EDIT_TOWN} open={editTown} onClose={() => setEditTown(false)} onSave={(newValue) => { saveTown(newValue); setEditTown(false); }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={user.country == null ? "" : user.country}
                                label={LABEL_COUNTRY}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditCountry(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyAlphanumericString} dialogTitle={LABEL_EDIT_COUNTRY} open={editCountry} onClose={() => setEditCountry(false)} onSave={(newValue) => { saveCountry(newValue); setEditCountry(false); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={user.mobilePhoneNumber == null ? "" : user.mobilePhoneNumber}
                                label={LABEL_PHONE_NUMBER}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditPhoneNumber(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyPhoneNumber} dialogTitle={LABEL_EDIT_PHONE_NUMBER} open={editPhoneNumber} onClose={() => setEditPhoneNumber(false)} onSave={(newPhoneNumber) => { savePhoneNumber(newPhoneNumber); setEditPhoneNumber(false); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={user.emailAddress == null ? "" : user.emailAddress}
                                label={LABEL_EMAIL_ADDRESS}
                                variant="standard"
                                fullWidth
                                disabled
                                InputProps={{
                                    endAdornment:
                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditEmailAddress(true) }} disabled={!isEditable}>
                                            <EditIcon />
                                        </IconButton>
                                }}
                            />
                            <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyEmailAddress} dialogTitle={LABEL_EDIT_EMAIL_ADDRESS} open={editEmailAddress} onClose={() => setEditEmailAddress(false)} onSave={(newEmailAddress) => { saveEmailAddress(newEmailAddress); setEditEmailAddress(false); }} />
                        </Grid>
                        {
                            contextAPI.admin ?
                                <Grid item xs={12}>
                                    <TextField
                                        value={user.portraitUrl == null ? "" : user.portraitUrl}
                                        label={LABEL_PORTRAIT_URL}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment:
                                                <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditPortraitURL(true) }} disabled={!isEditable}>
                                                    <EditIcon />
                                                </IconButton>
                                        }}
                                    />
                                    <EditTextDialog appAPI={appAPI} validate={validateAcceptAny} dialogTitle={LABEL_EDIT_PORTRAIT} open={editPortraitURL} onClose={() => setEditPortraitURL(false)} onSave={(newPortraitURL) => { savePortraitURL(newPortraitURL); setEditPortraitURL(false); }} />
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </TabPanel>
                {
                    user.callAgent ?
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <Grid spacing={3} container>
                                <Grid xs={6} item>
                                    <TextField
                                        value={user.vatID == null ? "" : user.vatID}
                                        label={"Ust-ID"}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment:
                                                <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditVatId(true) }} disabled={!isEditable}>
                                                    <EditIcon />
                                                </IconButton>
                                        }}
                                    />
                                    <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyUstID} dialogTitle={LABEL_EDIT_VAT_ID} open={editVatId} onClose={() => setEditVatId(false)} onSave={(newVatId) => { saveVatID(newVatId); setEditVatId(false); }} />

                                </Grid>
                                <Grid xs={6} item>
                                    <TextField
                                        value={user.taxID == null ? "" : user.taxID}
                                        label={"Steuernummer"}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment:
                                                <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditTaxId(true) }} disabled={!isEditable}>
                                                    <EditIcon />
                                                </IconButton>
                                        }}
                                    />
                                    <EditTextDialog appAPI={appAPI} validate={validatePossiblyEmptyAlphanumericString} dialogTitle={LABEL_EDIT_TAX_ID} open={editTaxId} onClose={() => setEditTaxId(false)} onSave={(taxId) => { saveTaxID(taxId); setEditTaxId(false); }} />
                                </Grid>
                            </Grid>
                            <Box style={{ height: "50px" }} />


                            <Grid spacing={3} container>
                                {
                                    !user.taxExemption ?
                                        <Grid xs={12} item>
                                            <TextField
                                                value={user.vatRate == null ? "" : user.vatRate}
                                                label={"Umsatzsteuersatz"}
                                                variant="standard"
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    endAdornment:
                                                        <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditVatRate(true) }} disabled={!isEditable}>
                                                            <EditIcon />
                                                        </IconButton>
                                                }}

                                            />
                                            <EditTextDialog appAPI={appAPI} validate={validateNonEmptyAlphanumericString} dialogTitle={LABEL_EDIT_VAT_RATE} open={editVatRate} onClose={() => setEditVatRate(false)} onSave={(vatRate) => { saveVatRate(vatRate); setEditVatRate(false); }} />
                                        </Grid>
                                        :
                                        null
                                }

                                <Grid xs={12} md={6} item>
                                    <FormGroup style={{ marginTop: "16px" }}>
                                        <FormControlLabel control={<Checkbox defaultChecked checked={user.taxExemption} onChange={(e) => saveTaxExemption(e.target.checked)} />} label="Umsatzsteuerbefreiung nach § 19 UStG" />
                                    </FormGroup>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <FormGroup style={{ marginTop: "16px" }}>
                                        <FormControlLabel control={<Checkbox defaultChecked checked={user.isNonRegisteredSoleTrader} onChange={(e) => saveNonRegisteredSoleTrader(e.target.checked)} />} label="Unternehmen ohne Handelsregistereintrag" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Box style={{ height: "50px" }} />
                            <Grid spacing={3} container>
                                <Grid xs={6} item>
                                    <TextField
                                        value={user.bank == null ? "" : user.bank}
                                        label={"Bank"}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment:
                                                <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditBank(true) }} disabled={!isEditable}>
                                                    <EditIcon />
                                                </IconButton>
                                        }}
                                    />
                                    <EditTextDialog appAPI={appAPI} validate={validateNonEmptyAlphanumericString} dialogTitle={LABEL_EDIT_BANK} open={editBank} onClose={() => setEditBank(false)} onSave={(vatRate) => { saveBank(vatRate); setEditBank(false); }} />
                                </Grid>
                                <Grid xs={6} item>
                                    <TextField
                                        value={user.bic == null ? "" : user.bic}
                                        label={"BIC"}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment:
                                                <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditBIC(true) }} disabled={!isEditable}>
                                                    <EditIcon />
                                                </IconButton>
                                        }}
                                    />
                                    <EditTextDialog appAPI={appAPI} validate={validateNonEmptyAlphanumericString} dialogTitle={LABEL_EDIT_BIC} open={editBIC} onClose={() => setEditBIC(false)} onSave={(vatRate) => { saveBIC(vatRate); setEditBIC(false); }} />
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField
                                        value={user.iban == null ? "" : user.iban}
                                        label={"IBAN"}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment:
                                                <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditIBAN(true) }} disabled={!isEditable}>
                                                    <EditIcon />
                                                </IconButton>
                                        }}
                                    />
                                    <EditTextDialog appAPI={appAPI} validate={validateNonEmptyAlphanumericString} dialogTitle={LABEL_EDIT_IBAN} open={editIBAN} onClose={() => setEditIBAN(false)} onSave={(vatRate) => { saveIBAN(vatRate); setEditIBAN(false); }} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        :
                        null
                }
                {
                    isEditable ?
                        (
                            <TabPanel value={tabIndex} index={nextTabIndex++}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={user.username == null ? "" : user.username}
                                            label={LABEL_USERNAME}
                                            variant="standard"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                endAdornment:
                                                    <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditUsername(true) }}>
                                                        <EditIcon />
                                                    </IconButton>
                                            }}
                                        />
                                        <EditUsernameDialog dialogTitle={LABEL_EDIT_USERNAME} open={editUsername} onClose={() => setEditUsername(false)} onSave={(newUsername) => { saveUsername(newUsername); setEditUsername(false) }} appAPI={appAPI} contextAPI={contextAPI} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={user.username == null ? "" : user.username}
                                            label={LABEL_PASSWORD}
                                            variant="standard"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                endAdornment:
                                                    <IconButton style={{ color: SECONDARY_COLOR, float: "right" }} onClick={() => { setEditPassword(true) }}>
                                                        <EditIcon />
                                                    </IconButton>
                                            }}
                                            type="password"
                                        />
                                        <EditPasswordDialog dialogTitle={LABEL_EDIT_PASSWORD} open={editPassword} onClose={() => setEditPassword(false)} onSave={(newPassword) => { savePassword(newPassword); setEditPassword(false); }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            style={{ whiteSpace: "pre-wrap", backgroundColor: SECONDARY_COLOR, color: "white", minWidth: "150px", marginTop: "25px" }}
                                            disabled={!user.canBeDisabled}
                                            onClick={disable}
                                        >
                                            {LABEL_DISABLE}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        )
                        :
                        null
                }
                {
                    isEditable && user.callAgent ?
                        (
                            <TabPanel value={tabIndex} index={nextTabIndex++}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12}>
                                        <TextField
                                            value={toEmptyStringIfNull(imprint).replaceAll("\$", "\n")}
                                            label={LABEL_IMPRINT}
                                            variant="standard"
                                            multiline
                                            rows={10}
                                            fullWidth
                                            onChange={(e) => {
                                                handleValidationResponse(validateNonEmptyAlphanumericString(e.target.value), (response) => setIsValidImprint(e.target.value == "" || response), () => setIsValidImprint(e.target.value == "" || false), appAPI.showMessage);
                                                setImprint(e.target.value)
                                            }}
                                            error={!isValidImprint}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            style={{ whiteSpace: "pre-wrap", backgroundColor: SECONDARY_COLOR, color: "white", minWidth: "150px", marginTop: "25px" }}
                                            onClick={() => {

                                                saveImprint(imprint)
                                            }}
                                        >
                                            {LABEL_SAVE}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        )
                        :
                        null
                }
                <TabPanel value={tabIndex} index={nextTabIndex++}>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={user.admin} disabled={!contextAPI.admin} />}
                                label={LABEL_ADMIN}
                                onChange={(e) => { saveIsAdmin(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={user.subscriber} disabled={!contextAPI.admin} />}
                                label={LABEL_IS_SUBSCRIBER}
                                onChange={(e) => { saveIsSubscriber(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={user.purchaser} disabled={!contextAPI.admin} />}
                                label={LABEL_IS_PURCHASER}
                                onChange={(e) => { saveIsPurchaser(e.target.checked) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={user.callAgent} disabled={!contextAPI.admin} />}
                                label={LABEL_SELLER}
                                onChange={(e) => { saveIsCallAgent(e.target.checked); setTabIndex(0) }}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                {
                    user.callAgent ?
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <SellerReview
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                userId={userId}
                            />
                        </TabPanel>
                        :
                        null
                }
                {
                    user.subscriber ?
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <Budget
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                userId={userId}
                            />
                        </TabPanel>
                        :
                        null
                }
                {
                    user.callAgent ?
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <PartnerManagement
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                userId={userId}
                            />
                        </TabPanel>
                        :
                        null
                }
                <Divider style={{ marginTop: "50px" }} />
                <Tabs value={tabIndex} onChange={(event, newValue) => { setTabIndex(newValue) }} centered >
                    <Tab onFocus={() => setTabIndex(0)} label={LABEL_PERSON} {...a11yProps(0)} />
                    {
                        user.callAgent ?
                            <Tab label={"Abrechnungsdaten"} {...a11yProps(2)} />
                            :
                            null
                    }
                    {
                        isEditable ?
                            <Tab label={LABEL_LOGIN} {...a11yProps(1)} />
                            :
                            null
                    }
                    {
                        isEditable && user.callAgent ?
                            <Tab label={LABEL_IMPRINT} {...a11yProps(3)} />
                            :
                            null
                    }
                    <Tab label={LABEL_ROLES} {...a11yProps(4)} />
                    {
                        user.callAgent ?
                            <Tab label={LABEL_REVIEWS} {...a11yProps(5)} />
                            :
                            null
                    }
                    {
                        user.subscriber ?
                            <Tab label={LABEL_BUDGET} {...a11yProps(6)} />
                            :
                            null
                    }
                    {
                        user.callAgent ?
                            <Tab label={LABEL_PARTNER_MANAGEMENT} {...a11yProps(7)} />
                            :
                            null
                    }
                </Tabs>
            </AccordionDetails>
        </Accordion>
    )
}

