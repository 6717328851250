import { React, useEffect, useState } from 'react';

import { Box } from '@mui/system/';
import { AccordionSummary, AccordionDetails, Accordion, Grid, Button, IconButton, TextField, Tabs, Tab, CardContent, Paper, CircularProgress, Typography, Divider, Stack } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import GavelIcon from '@mui/icons-material/Gavel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PRIMARY_COLOR, SECONDARY_COLOR } from './../App';
import { a11yProps, handleResponse, TabPanel } from './../Utility';
import { bidAPI, fetchAuctionAPI, fetchAuctionStatusAPI } from '../api/auction_api';
import { SellerReview } from './Review';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchUserCanBuylAPI } from '../api/user_api';


const LABEL_CATEGGORY = "Kategorie";
const LABEL_BID = "Bieten";
const LABEL_DETAILS = "Details";
const LABEL_DESCRIPTION = "Beschreibung";
const LABEL_COMMENT = "Kommentar";

const LABEL_SELLER_DETAILS = "Anbieter";
const LABEL_CURRENT_HIGHEST_BID = "Aktuelles Höchstgebot"
const LABEL_NO_BID = "Kein Gebot"
const LABEL_YOUR_BID = "Ihr Gebot"
const LABEL_ENDED = "Beendet"
const LABEL_AUCTION_END_DATE = "Auktionsende"
const LABEL_ITEM_TYPE = "Art";
const LABEL_ZIP_CODE = "Postleitzahl";
const LABEL_BIRTH_YEAR = "Geburtsjahr";
const LABEL_PRICE = "Preis";
const LABEL_B2B = "B2B";
const LABEL_B2C = "B2C";
const LABLE_CURRENCY = "EUR"
const LABEL_VAT_RATE = "Umsatzsteuersatz"
const LABEL_PAYMENT_TERM = "Zahlungsziel"

const MSG_HIGHEST_BIDDER = "Sie sind der Höchstbietende.";
const MSG_OUTBIDDED = "Sie wurden überboten.";
const MSG_BID_NOT_ACCEPTED = "Ihr Gebot konnte nicht berücksichtigt werden. ";


export default function Auction(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const auctionId = props.auctionId;

    const [auction, setAuction] = useState(null);
    const [updateAuction, setUpdateAuction] = useState(true);
    const [updateAuctionStatus, setUpdateAuctionStatus] = useState(true);

    const [isActive, setActive] = useState(true);
    const [curBid, setCurBid] = useState(null);
    const [highestBid, setHighestBid] = useState(null);
    const [isHighestBidder, setHighestBidder] = useState(false);

    const [canBuy, setCanBuy] = useState(false); 
    const [updateCanBuy, setUpdateCanBuy] = useState(true);


    const auctionEndDate = auction == null ? null : new Date(auction.endTime);
    const auctionProgress = auction == null ? null : calcProgress(auction.startTime, auction.duration);

    const bid = () => {
        if (auction != null && curBid != null) {
            handleResponse(bidAPI(contextAPI.username, contextAPI.password, auction.id, curBid), (isHighestBidder) => {
                if (isHighestBidder) {
                    setHighestBidder(isHighestBidder);
                    appAPI.showMessage(MSG_HIGHEST_BIDDER);
                }
                else {
                    appAPI.showMessage(MSG_BID_NOT_ACCEPTED);
                }
                setUpdateAuctionStatus(true);
                setCurBid(null);
            }, appAPI.showMessage)
        }
    }

    useEffect(() => {

        let interval = null;

        const doUpdateAuctionStatus = async () => {
            handleResponse(fetchAuctionStatusAPI(contextAPI.username, contextAPI.password, auctionId), (auctionStatus) => {
                setActive(auctionStatus.status == "ACTIVE")
                setHighestBid(auctionStatus.maxBid)
                if (isHighestBidder && auctionStatus.highestBidderId !== contextAPI.id) {
                    appAPI.showMessage(MSG_OUTBIDDED)
                }
                setHighestBidder(auctionStatus.highestBidderId === contextAPI.id)
                setUpdateAuctionStatus(false)
            }, appAPI.showMessage)
        }

        const doUpdateAuction = async () => {
            handleResponse(fetchAuctionAPI(contextAPI.username, contextAPI.password, auctionId), (response) => {
                setAuction(response);
            }, appAPI.showMessage)
            setUpdateAuction(false)
        }

        if (updateAuction) {
            doUpdateAuction();
        }

        if (updateAuctionStatus) {
            doUpdateAuctionStatus();
        }
        
        if (updateCanBuy) {
            handleResponse(fetchUserCanBuylAPI(contextAPI.id, contextAPI.username, contextAPI.password), (response) => {setCanBuy(response); setUpdateCanBuy(false);}, appAPI.showMessage);
        }

        if (isActive) {
            interval = setInterval(() => setUpdateAuctionStatus(true), 2000);
            return () => {
                clearInterval(interval);
            };
        }


 

        return ()=>{};
    })

    const statusColor = () => { 
        return isHighestBidder ? PRIMARY_COLOR : "gray"
    }
         
    const [tabIndex, setTabIndex] = useState(0);

    return (
        auction != null ?
            <Accordion style={{ marginBottom: "50px" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    style={
                        {
                            backgroundColor: PRIMARY_COLOR,
                            color: "white"
                        }
                    }
                >

                    <Typography variant="span" style={{ fontFamily: "Arial, Helvetica" }}>
                        {auction == null || auction.zipCode == null || auction.zipCode == "" ? "" : auction.zipCode + ": "}{auction == null ? "" : auction.categoryName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                    <TabPanel value={tabIndex} index={0}>

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    value={auction != null && auction.b2b ? LABEL_B2B : LABEL_B2B}
                                    label={LABEL_ITEM_TYPE}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={auction != null ? auction.zipCode : ""}
                                    label={LABEL_ZIP_CODE}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={auction == null ? "" : auction.birthdate}
                                    label={LABEL_BIRTH_YEAR}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={(auction == null ? "" : auction.paymentTerm) + " Tage"}
                                    label={LABEL_PAYMENT_TERM}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={(auction == null ? "" : auction.vatRate) + " %"} 
                                    label={LABEL_VAT_RATE}
                                    variant="standard"
                                    fullWidth 
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper style={{ display: "inline-block", borderTop: PRIMARY_COLOR + " 10px solid", marginTop: "75px" }}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {isActive && highestBid != null ? LABEL_CURRENT_HIGHEST_BID : null}
                                        </Typography>
                                        <Typography variant="h5" component="div" style={{ color: statusColor() }}>
                                            {isActive ? (highestBid == null ? LABEL_NO_BID : highestBid + " EUR") : LABEL_ENDED}
                                        </Typography>
                                        <Typography variant="p" component="div" style={{ color: statusColor() }}>
                                            {isActive && highestBid == null && auction != null && auction.minBid != null ? "Mindestgebot: " + auction.minBid + " EUR" : ""}
                                        </Typography>
                                        <Box style={{ marginTop: "5px" }}>
                                            {
                                                isActive ?
                                                    <CircularProgress
                                                        style={{ color: PRIMARY_COLOR, marginLeft: "5px" }}
                                                        value={auctionProgress}
                                                        size={12}
                                                        thickness={25}
                                                        variant="determinate"
                                                    />
                                                    :
                                                    null
                                            }
                                            <Typography
                                                style={{ display: "inline-block", marginLeft: "5px", fontSize: 14 }}
                                                color="text.secondary"
                                                gutterBottom>
                                                {isActive && auctionEndDate != null ? LABEL_AUCTION_END_DATE + ": " + auctionEndDate.toLocaleDateString() + " um " + auctionEndDate.toLocaleTimeString() + " Uhr" : null}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={9}>
                                                <TextField
                                                    value={curBid == null ? "" : curBid}
                                                    label={LABEL_YOUR_BID}
                                                    variant="standard"
                                                    style={{ color: PRIMARY_COLOR }}
                                                    disabled={!isActive}
                                                    onChange={(e) => {
                                                        if (e.target.value === "" || /^[0-9\b]+$/.test(e.target.value)) {
                                                            setCurBid(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <IconButton style={{ color: PRIMARY_COLOR }} onClick={() => setUpdateAuctionStatus(true)} disabled={!isActive}>
                                                    <AutorenewIcon />
                                                </IconButton>
                                                <Button startIcon={<GavelIcon />} style={{ color: PRIMARY_COLOR }} onClick={bid} disabled={!isActive || curBid == null || !canBuy}>
                                                    {LABEL_BID}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Paper>
                            </Grid>  
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    value={auction != null ? auction.comment.replaceAll("$", "\n") + "" : ""}
                                    label={LABEL_COMMENT}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        {
                            auction == null ?
                                null
                                :
                                <SellerReview
                                    appAPI={appAPI}
                                    contextAPI={contextAPI}
                                    userId={auction.sellerUserId}
                                />
                        }
                    </TabPanel>
                    <Divider style={{ marginTop: "50px" }} />
                    <Tabs value={tabIndex} onChange={(event, newValue) => { setTabIndex(newValue) }} TabIndicatorProps={{ style: { background: PRIMARY_COLOR } }}>
                        <Tab label={LABEL_DETAILS} {...a11yProps(0)} />
                        <Tab label={LABEL_COMMENT} {...a11yProps(2)} />
                        <Tab label={LABEL_SELLER_DETAILS} {...a11yProps(2)} />
                    </Tabs>
                </AccordionDetails>
            </Accordion>
            :
            <Typography>Nicht verfügbar</Typography>
    )
}

const calcProgress = (startTime, duration) => {
    let progress = 100;
    const elapsedTime = (Date.now() - startTime);
    if (elapsedTime < duration) {
        progress = Math.round((elapsedTime / duration) * 100)
    }
    return progress;
}
