import { useEffect, useState } from "react";
import { handleResponse } from "../Utility";
import { addNote, fetchCreatedAPI, fetchLeads, fetchNotes, fetchPurchasesAPI } from "../api/lead_api";
import { Box, Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import Lead from "../fragments/Lead";
import AddLead from "../fragments/AddLead";
import { fetchSalesCsvAPI } from "../api/reporting_api";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../App";
import RefreshButton from "../components/RefreshButton";
import { fetchUserCanSellAPI } from "../api/user_api";


const LABEL_ADD_LEAD = "Lead hinzufügen"
const LABEL_EXPORT = "Export"

const MSG_NO_PURCHAES = "Keine Ergebnisse"

export default function Sales(props) {

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI

    const [createdList, setCreatedList] = useState([]);
    const [updateCreatedList, setUpdateCreatedList] = useState(true);

    const [canSell, setCanSell] = useState(false);
    const [updateCanSell, setUpdateCanSell] = useState(true);


    const [open, setOpen] = useState(false);
    const [maxCount, setMaxCount] = useState(3);


    useEffect(() => {
        if (updateCreatedList) {
            handleResponse(fetchCreatedAPI(contextAPI.username, contextAPI.password), (response) => {setCreatedList(response); setUpdateCreatedList(false);}, appAPI.showMessage);
        }
        if(updateCanSell) {
            handleResponse(fetchUserCanSellAPI(contextAPI.id, contextAPI.username, contextAPI.password), (response) => {setCanSell(response); setUpdateCanSell(false);}, appAPI.showMessage);
        }
    })

    const downloadSales = () => {
        handleResponse(fetchSalesCsvAPI(contextAPI.username, contextAPI.password), (response) => {
            var csvContent = "data:text/csv;charset=utf-8," + response;
            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }, appAPI.showMessage)
    }

    let count = 0;

    window.onwheel = () => setMaxCount(maxCount+2)
    window.ontouchmove = () => setMaxCount(maxCount + 1);

    return ( 
        updateCreatedList ?  
            <LinearProgress/>
            :
            <Grid container>
                <Grid item xs={12} > <Box style={{ height: "50px" }} /></Grid>
                <Grid item xs={12}>
                    <Button disabled={!canSell} variant="contained" onClick={() => setOpen(true)} startIcon={<AddIcon />} style={{backgroundColor: SECONDARY_COLOR, marginRight: "25px"}}>
                        {LABEL_ADD_LEAD}
                    </Button>
                    <Button onClick={downloadSales} variant="contained" startIcon={<CloudDownloadIcon />} style={{backgroundColor: SECONDARY_COLOR, marginRight: "25px"}}>
                        {LABEL_EXPORT}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ height: "50px" }} />
                    { 
                        createdList.length > 0 ?
                            createdList.map((leadId) => {
                                count += 1
                                return (
                                    count <= maxCount ? 
                                        <Lead
                                            key={leadId}
                                            appAPI={appAPI}
                                            contextAPI={contextAPI}
                                            leadId={leadId}
                                        />
                                        : 
                                        null
                                )
                            })  
                            :
                            <Typography variant='p'>{MSG_NO_PURCHAES}</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Box style={{marginTop: "50px"}} />
                    <AddLead 
                        appAPI={appAPI}
                        contextAPI={contextAPI}
                        reload={() => {setUpdateCreatedList(true)}}
                        open={open}
                        setOpen={setOpen}
                    />
                </Grid>
            </Grid>
    ) 
} 
 
 /*
 
                 <Grid item xs={12}>
                    <TextField
                        value={searchTerm}
                        label={LABEL_SEARCH}
                        variant="standard"
                        onChange={(e) => {setSearchTerm(e.target.value); setUpdateCreatedList(true)}}
                        fullWidth
                    />
                </Grid>
 */