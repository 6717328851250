import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Offer from "../fragments/Offer";
import Lead from "../fragments/Lead";

export default function LeadWrapper(props) {

    const { leadId } = useParams();

    return (
        <Box style={{ marginTop: "50px" }}>
            <Lead
                appAPI={props.appAPI}
                contextAPI={props.contextAPI}
                leadId={leadId}
            />
        </Box>
    )
}

