import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import Lead, { LeadDetails } from "../fragments/Lead";
import { HM_LOGO_URL, PRIMARY_COLOR, SECONDARY_COLOR } from "../App";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { confirmLeadAPI, fetchLeadByConfirmationCodeAPI } from "../api/lead_api";
import { handleResponse } from "../Utility";

const TEXT_HEADING = "Vielen Dank für Ihr Interesse!"
const TEXT_EXPLANATION = "Damit wir für Sie die angeforderten Angebots- und Informationsunterlagen erstellen können, müssen Sie in die Verarbeitung Ihrer personenbezogenen Daten einwilligen."
const TEXT_CONSENT = "Ich erkläre mich mit der Verarbeitung der oben genannten personenbezogenen Daten  einverstanden."
const LABEL_SEND = "Absenden"
const TEXT_NOT_AVAILABLE = "Nicht verfügbar"
const TEXT_THANKS = "Danke!"

export default function Confirmation(props) {

    const { leadId, confirmationCode } = useParams();

    const appAPI = props.appAPI;

    const [consent, setConsent] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const [updateLead, setUpdateLead] = useState(true);
    const [lead, setLead] = useState(null)

    useEffect(() => {

        const fetchLead = () => {
            handleResponse(fetchLeadByConfirmationCodeAPI(leadId, confirmationCode), (response) => {
                setLead(response);
                setUpdateLead(false)
            }, appAPI.showMessage);
        }

        if (updateLead) {
            fetchLead();
        }
    })

    const confirm = () => {
        handleResponse(confirmLeadAPI(leadId, confirmationCode), (response) => {
            setConfirmed(response)
            if (response) {
                appAPI.showMessage("Vielen Dank!");
            }
            else {
                appAPI.showMessage("Es ist ein Fehler aufgertreten. Bitte versuchen Sie es erneut.")
            }
        }, appAPI.showMessage);
    }

    return (
        <Box>
            <Box style={{ backgroundColor: "#005e52", height: "100px"}}>

            </Box>
            <Box style={{ display: "block", width: "90%", marginLeft: "auto", marginRight: "auto", paddingTop: "25px", paddingBottom: "50px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box style={{ height: "25px" }}></Box>
                    </Grid>
                    {
                        lead == null ?
                            <Typography>{TEXT_NOT_AVAILABLE}</Typography>
                            :
                            null
                    }

                    {
                        !confirmed && lead != null ?
                            <Grid item xs={12}>
                                <Typography variant="h3" align="center" color={"#005e52"}>
                                    {TEXT_HEADING}
                                </Typography>
                            </Grid>
                            :
                            null
                    }

                    {
                        !confirmed && lead != null ?
                            <Grid item xs={12}>
                                <ContactPerson                                     
                                    lead={lead}
                                    style={{marginTop: "50px", marginBottom: "50px"}}
                                />
                            </Grid>
                            :
                            null
                    }



                    {
                        !confirmed && lead != null ?
                            <Grid item xs={12}>
                                <Divider style={{marginTop: "50px", marginBottom: "50px"}}/>
                                <Typography>
                                    {
                                        TEXT_EXPLANATION
                                    }
                                </Typography>
                            </Grid>
                            :
                            null
                    }
                    {
                        !confirmed && lead != null ?
                            <Grid item xs={12}>
                                <Box style={{ height: "25px" }} />
                                <LeadDetails
                                    lead={lead}
                                />
                                <Box style={{ height: "25px" }} />
                            </Grid>
                            :
                            null
                    }
                    {
                        !confirmed && lead != null ?
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    style={{ whiteSpace: "pre-wrap", backgroundColor: "#005e52", color: "white", minWidth: "150px" }}
                                    onClick={confirm}
                                    disabled={!consent}
                                >
                                    {LABEL_SEND}
                                </Button>
                            </Grid>
                            :
                            null
                    }
                    {
                        !confirmed && lead != null ?
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label={TEXT_CONSENT}
                                    control={
                                        <Checkbox checked={consent} onClick={(e) => { setConsent(e.target.checked) }} />
                                    }
                                />
                            </Grid>
                            :
                            null
                    }
                    {
                        confirmed ?
                            <Grid item xs={12}>
                                <Typography variant="h3" align="center" color={"#005e52"}>
                                    {TEXT_THANKS}
                                </Typography>
                            </Grid>
                            :
                            null
                    }
                </Grid>
            </Box>
        </Box>
    )
}


function ContactPerson(props) {
    
    const firstName = props.lead.creatorFirstName
    const lastName = props.lead.creatorLastName
    const portraitUrl = props.lead.creatorPortraitUrl;

    return (
        <Box>
            <Box style={{ marginLeft: "auto", marginRight: "auto", width: "62px", padding: "10px" }}>
                <Avatar style={{ boxShadow: "0px 0px 5px gray", border: "3px gray solid" }} alt="Remy Sharp" src={portraitUrl} sx={{ width: 56, height: 56 }} />
            </Box>
            <Typography style={{ textAlign: "center", color: "black", fontWeight: "bold" }}>Ihr Gesprächspartner</Typography>
            <Typography style={{ textAlign: "center", }}>{firstName} {lastName}</Typography>

            <img style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "75px", padding:"15px", marginTop: "25px"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/HanseMerkur_Logo_2018.svg/500px-HanseMerkur_Logo_2018.svg.png"></img>
            <Typography style={{ textAlign: "center", color: "black"}}>
                Ich arbeite im Auftrag verschiedener Agenturen der HanseMerkur Krankenversicherung AG.
            </Typography>
        
        </Box>
    )
}  