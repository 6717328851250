import { useEffect, useState } from "react";
import { Grid, Rating, Typography } from "@mui/material";

import { assessLeadAPI, fetchAssessmentsAPI } from "../api/lead_api";

import { handleResponse } from "../Utility";
import { fetchUserAssessmentsAPI } from "../api/user_api";

const MSG_NO_DATA = "Keine Daten"

export function LeadReview(props) {



    const appAPI = props.appAPI
    const contextAPI = props.contextAPI
    const lead = props.lead;

    const [assessmentList, setAssessmentList] = useState([]);
    const [updateAssessmentList, setUpdateAssessmentList] = useState(true);


    useEffect(() => {
        if (updateAssessmentList) {
            handleResponse(fetchAssessmentsAPI(contextAPI.username, contextAPI.password, lead.id), (response) => {setAssessmentList(response); setUpdateAssessmentList(false)}, appAPI.showMessage)
        }
    })

    let id = 0

    return (
        <Grid container spacing={3}>
            {
                assessmentList.map((category) => {
                    return(
                        <Grid key={id++} item xs={6} md={3} >
                                <Typography component="legend">
                                    {category.description}
                                </Typography>
                                <Rating
                                    value={category.value}
                                    disabled={category.value !== null || props.readOnly}
                                    onChange={(event, newValue) => {
                                        handleResponse(assessLeadAPI(contextAPI.username, contextAPI.password, lead.id, category.categoryId, newValue), (response) => setUpdateAssessmentList(true), appAPI.showMessage)
                                    }}
                                />
                        </Grid>
                    ) 
                })  
            }
        </Grid> 
    )
 
}



export function SellerReview(props) {

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI
    const userId = props.userId;

    const [assessmentList, setAssessmentList] = useState([]);
    const [updateAssessmentList, setUpdateAssessmentList] = useState(true);


    useEffect(() => {
        if (updateAssessmentList) {
            handleResponse(fetchUserAssessmentsAPI(userId, contextAPI.username, contextAPI.password), (response) => {setAssessmentList(response); setUpdateAssessmentList(false)}, appAPI.showMessage)
        }
    })

    let id = 0

    return (
        <Grid container spacing={3}>
            {
                assessmentList.length > 0 ?
                    assessmentList.map((category) => {
                        return(  
                            <Grid key={id++} item xs={6} md={3} >
                                    <Typography component="legend">
                                        {category.description}
                                    </Typography>
                                    <Rating
                                        value={category.value}
                                        disabled={true}
                                    />
                            </Grid>
                        )
                    })  
                    :
                    <Grid item xs={6} md={3} >
                        <Typography variant='p'>{MSG_NO_DATA}</Typography>
                    </Grid>
                    
            }
        </Grid> 
    )
}  