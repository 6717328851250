import { buildUrl, formatParam } from "../Utility"

export const fetchOfferAPI = (username, password, offerId) => {
    return fetch(buildUrl("offer/" +  formatParam(offerId) + "?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
} 

export const fetchOffersAPI = (username, password) => {
    return fetch(buildUrl("offer/all?username=" + formatParam(username) + "&password=" + formatParam(password)), { method: "GET" })
} 

export const buyOfferAPI = (username, password, offerId) => {
    return  fetch(buildUrl("offer/buy" + "?username=" + formatParam(username) + "&password=" + formatParam(password) + "&offerId=" + formatParam(offerId)), { method: "PUT" });
}
