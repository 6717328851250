export const instantiate = (formDescriptor) => {
    const formInstance = {
        main: [], 
        roles: [], 
        nextRoleInstanceId: 0, 
        roleInstanceCounts: {}
    }
    const formData = {
        main: {}, 
        roleInstances: {}, 
    }
    for(let index = 0; index < formDescriptor.main.length; index++) {
        const curInput = formDescriptor.main[index];
        formInstance.main.push(curInput)
        formData.main[curInput.id] = {}
        formData.main[curInput.id].label = curInput.label
        if(curInput.type === "text") {
            formData.main[curInput.id].regex = curInput.regex
            formData.main[curInput.id].value = curInput.default != null ? curInput.default : ""
            formData.main[curInput.id].isValid = (curInput.regex == null || curInput.default != null || new RegExp(curInput.regex).test(""))
        }
        else if(curInput.type === "select") {
            let defaultOption = null; 
            for(let optionIndex = 0; optionIndex < curInput.options.length; optionIndex++) {
                const curOption = curInput.options[optionIndex]; 
                if(curOption.isDefault) {
                    defaultOption = curOption;  
                    break;
                }
            }
            formData.main[curInput.id].value = defaultOption == null ? null : defaultOption.value
            formData.main[curInput.id].isValid =  true
        }
    }  
    return [formInstance, formData];
}

export const addRole = (formDescriptor, formInstance, formData, roleId) => {
    for(let roleIndex = 0; roleIndex < formDescriptor.roles.length; roleIndex++) {
        const curRole = JSON.parse(JSON.stringify(formDescriptor.roles[roleIndex]));
        if(curRole.id == roleId && (formInstance.roleInstanceCounts[roleId] == null || formInstance.roleInstanceCounts[roleId] < curRole.maxCount)) {
            curRole.id = formInstance.nextRoleInstanceId
            curRole.typeId = roleId
            formInstance.roles.push(curRole)
            formData.roleInstances[curRole.id] = {}
            formData.roleInstances[curRole.id].name = curRole.name;
            for(let inputIndex = 0; inputIndex < curRole.inputs.length; inputIndex++) {
                const curInput = curRole.inputs[inputIndex];
                formData.roleInstances[curRole.id][curInput.id] = {}
                formData.roleInstances[curRole.id][curInput.id].label = curInput.label
                if(curInput.type === "text") {
                    formData.roleInstances[curRole.id][curInput.id].regex = curInput.regex
                    formData.roleInstances[curRole.id][curInput.id].value = curInput.default != null ? curInput.default : ""
                    formData.roleInstances[curRole.id][curInput.id].isValid =  (curInput.regex == null || curInput.default != null || new RegExp(curInput.regex).test(""))
                }
                else if(curInput.type === "select") {
                    let defaultOption = null;
                    for(let optionIndex = 0; optionIndex < curInput.options.length; optionIndex++) {
                        const curOption = curInput.options[optionIndex];
                        if(curOption.isDefault) {
                            defaultOption = curOption; 
                            break;
                        }
                    }
                    formData.roleInstances[curRole.id][curInput.id].value = defaultOption == null ? null : defaultOption.value
                    formData.roleInstances[curRole.id][curInput.id].isValid = true
                } 
            }
            if(formInstance.roleInstanceCounts[roleId]== null) {
                formInstance.roleInstanceCounts[roleId] = 0
            }
            formInstance.roleInstanceCounts[roleId] = formInstance.roleInstanceCounts[roleId] + 1
            formInstance.nextRoleInstanceId = formInstance.nextRoleInstanceId + 1
        }
    }
    return [formInstance, formData];
}

export const removeRole = (formInstance, formData, roleId) => {
    const formInstanceCpy = JSON.parse(JSON.stringify(formInstance));
    {
        let typeId = null;
        formInstanceCpy.roles = [];
        for(let index = 0; index < formInstance.roles.length; index++) {
            const curRole = formInstance.roles[index];
            if(curRole.id !== roleId) {
                formInstanceCpy.roles.push(curRole);
            }
            else {
                typeId = curRole.typeId;
            } 
        }
        if(typeId !== null) {
            formInstanceCpy.roleInstanceCounts[typeId] = formInstanceCpy.roleInstanceCounts[typeId] - 1;
        }
    }
    const formDataCpy = JSON.parse(JSON.stringify(formData));
    delete formDataCpy.roleInstances[roleId];


    return [formInstanceCpy, formDataCpy]
}

export const isValid = (formData) => {
    let isValueVar = true;
    if(formData != null) {
        let mainKeyArr = Object.keys(formData.main)
        for(let index = 0; index < mainKeyArr.length && isValueVar; index++) {
            let key = mainKeyArr[index];
            isValueVar = isValueVar && (formData.main[key].isValid == null || formData.main[key].isValid)
        }
        let roleKeyArr = Object.keys(formData.roleInstances);
        for(let roleIndex = 0; roleIndex < roleKeyArr.length && isValueVar; roleIndex++) {
            let role = formData.roleInstances[roleKeyArr[roleIndex]];
            let inputKeyArr = Object.keys(role)
            for(let inputIndex = 0; inputIndex < inputKeyArr.length; inputIndex++) {
                isValueVar = isValueVar && (formData.roleInstances[roleKeyArr[roleIndex]][inputKeyArr[inputIndex]].isValid == null || formData.roleInstances[roleKeyArr[roleIndex]][inputKeyArr[inputIndex]].isValid) 
            }
        }
    }
    return isValueVar;
}   