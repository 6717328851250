import { buildUrl, formatParam } from "../Utility"

export const validatePossiblyEmptyAlphanumericString = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyAlphanumericStr/?str=" + formatParam(str)));
} 
 
export const validateNonEmptyAlphanumericString = (str) => {
    return fetch(buildUrl("validation/nonEmptyAlphanumericStr/?str=" + formatParam(str)));
} 

export const validateNonEmptyAlphanumericText = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyAlphanumericText/?str=" + formatParam(str)));
} 

export const validatePossiblyEmptyPhoneNumber = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyPhoneNumber/?str=" + formatParam(str)));
} 

export const validateNonEmptyPhoneNumber = (str) => {
    return fetch(buildUrl("validation/nonEmptyPhoneNumber/?str=" + formatParam(str)));
} 

export const validateNonEmptyEmailAddress = (str) => {
    return fetch(buildUrl("validation/nonEmptyEmailAddress/?str=" + formatParam(str)));
} 

export const validatePossiblyEmptyEmailAddress = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyEmailAddress/?str=" + formatParam(str)));
} 

export const validateNonEmptyHouseNo = (str) => {
    return fetch(buildUrl("validation/nonEmptyHouseNo/?str=" + formatParam(str)));
} 

export const validatePossiblyEmptyHouseNo = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyHouseNo/?str=" + formatParam(str)));
} 

export const validateNonEmptyZipCode = (str) => {
    return fetch(buildUrl("validation/nonEmptyZipCode/?str=" + formatParam(str)));
} 
 
export const validatePossiblyEmptyZipCode = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyZipCode/?str=" + formatParam(str)));
} 

export const validatePossiblyEmptyUstID = (str) => {
    return fetch(buildUrl("validation/possiblyEmptyUstID/?str=" + formatParam(str)));
} 
 

export const validateAcceptAny = (str) => {
    return fetch(buildUrl("validation/any/?str=" + formatParam(str)));
} 