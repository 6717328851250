import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Offer from "../fragments/Offer";

export default function OfferWrapper(props) {

    const { offerId } = useParams();

    return (
        <Box style={{ marginTop: "50px" }}>
            <Offer
                appAPI={props.appAPI}
                contextAPI={props.contextAPI}
                offerId={offerId}
                reload={()=>{}}
            />
        </Box>
    )
}

