import React, { useEffect, useState } from "react";
import { LABEL_PUBLIC_COMMENT as LABEL_COMMENT, handleResponse, handleValidationResponse } from "../Utility";
import { fetchCategoriesAPI, fetchCountriesAPI } from "../api/constants_api";
import { addLeadAPI, assignAPI, createAuctionAPI, createOfferAPI, isBudgetAvailableAPI } from "../api/lead_api";
import { Box } from '@mui/system';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PeopleIcon from '@mui/icons-material/People';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import Lead from '../fragments/Lead';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import GavelIcon from '@mui/icons-material/Gavel';
import WarningIcon from '@mui/icons-material/Warning';

import { buildUrl, formatParam, HTTP_STATUS_SUCCESS, PRIMARY_COLOR, SECONDARY_COLOR } from './../App';
import { LABEL_BIRTHDATE, LABEL_EMAIL_ADDRESS, LABEL_FIRM, LABEL_FIRST_NAME, LABEL_HOUSE_NO, LABEL_LAST_NAME, LABEL_PHONE_NUMBER, LABEL_PRIVATE_COMMENT, LABEL_PRODUCT, LABEL_PUBLIC_COMMENT, LABEL_STREET, LABEL_TOWN, LABEL_ZIP_CODE, toEmptyStringIfNull } from './../Utility';
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, InputLabel, LinearProgress, Paper } from '@mui/material';
import { addRole, instantiate, isValid, removeRole } from "./Form";
import { json } from "react-router-dom";
import { validateNonEmptyAlphanumericString, validateNonEmptyEmailAddress, validateNonEmptyHouseNo, validateNonEmptyPhoneNumber, validateNonEmptyZipCode, validatePossiblyEmptyAlphanumericString } from "../api/validation_api";
import { ClassNames } from "@emotion/react";

const LABEL_BUDGET_PER_MONTH = "Budget pro Monat in EUR"
const LABEL_EDIT_BUDGET = "Budget ändern"
const LABEL_ADD_LEAD = "Lead hinzufügen"
const LABEL_SAVE = "Speichern"
const LABEL_YEAR_OF_BIRTH = "Geburtsjahr"
const LABEL_PAYMENT_TERM = "Zahlungsziel"
const LABEL_DAYS = "Tage"


const MSG_CHANGES_SAVED = "Änderungen gespeichert."
const MSG_ERROR_OCCURED = "Es trat ein Fehler auf. HTTP-Status-Code: ";
const MSG_RUNNING_OUT_OF_BUDGET = "Es steht kein Budget zur Verfügung."

const DECLARTION_OF_CONSENT = "Ich erkläre, dass die Einwilligung aller betroffenen Personen in die Vearbeitung ihrer personenbezogenen Daten vorliegt."

const MODE_ASSIGNMENT = 0
const MODE_AUCTION = 1
const MODE_OFFER = 2


const pkvFormDescriptor = {
    main: [
        {
            id: 0,
            type: "select",
            label: "Berufsstatus",
            options: [
                {
                    name: "Angestellter/-e",
                    value: "Angestellter/-e",
                    isDefault: false,
                },
                {
                    name: "Selbständiger/-e",
                    value: "Selbständiger/-e",
                    isDefault: true,
                },
                {
                    name: "Student/-in",
                    value: "Student/-in",
                    isDefault: false,
                },
                {
                    name: "Nicht erwerbstätig",
                    value: "Nicht erwerbstätig",
                    isDefault: false,
                },
            ]
        },
        {
            id: 1,
            type: "select",
            label: "Jährliches Einkommen",
            options: [
                {
                    name: "keine Angabe",
                    value: "keine Angabe",
                    isDefault: true,
                },
                {
                    name: "mindestens 69.300 €",
                    value: "mindestens 69.300 €",
                    isDefault: false,
                },
                {
                    name: "weniger als 69.300 €",
                    value: "weniger als 69.300 €",
                    isDefault: false,
                },
            ]
        },
        {
            id: 2,
            type: "select",
            label: "Vorversicherung",
            options: [
                {
                    name: "keine Angabe",
                    value: "keine Angabe",
                    isDefault: true,
                },
                {
                    name: "PKV",
                    value: "PKV",
                    isDefault: false,
                },
                {
                    name: "GKV-Mitgliedschaft",
                    value: "GKV-Mitgliedschaft",
                    isDefault: false,
                },
                {
                    name: "GKV-Familienversicherung",
                    value: "GKV-Familienversicherung",
                    isDefault: false,
                },
            ]
        },
        {
            id: 3,
            type: "select",
            label: "Aktueller Monatsbeitrag",
            options: [
                {
                    name: "keine Angabe",
                    value: "keine Angabe",
                    isDefault: true,
                },
                {
                    name: "weniger als 200 €",
                    value: "weniger als 200 €",
                    isDefault: false,
                },
                {
                    name: "200 - 299 €",
                    value: "200 - 299 €",
                    isDefault: false,
                },
                {
                    name: "300 - 399 €",
                    value: "300 - 399 €",
                    isDefault: false,
                },
                {
                    name: "400 - 499 €",
                    value: "400 - 499 €",
                    isDefault: false,
                },
                {
                    name: "500 - 599 €",
                    value: "500 - 599 €",
                    isDefault: false,
                },
                {
                    name: "600 - 699 €",
                    value: "600 - 699 €",
                    isDefault: false,
                },
                {
                    name: "700 - 799 €",
                    value: "700 - 799 €",
                    isDefault: false,
                },
                {
                    name: "800 - 899 €",
                    value: "800 - 899 €",
                    isDefault: false,
                },
                {
                    name: "900 - 999 €",
                    value: "900 - 999 €",
                    isDefault: false,
                },
                {
                    name: "1000 € oder mehr",
                    value: "1000 € oder mehr",
                    isDefault: false,
                },
            ]
        },
        {
            id: 4,
            type: "select",
            label: "DAK-Business-Bonus",
            options: [
                {
                    name: "Ja",
                    value: "Ja",
                    isDefault: false,
                },
                {
                    name: "Nein",
                    value: "Nein",
                    isDefault: true,
                },
            ]
        },
    ],
    roles: [
        {
            id: 0,
            name: "Partner",
            icon: <PeopleIcon />,
            maxCount: 1,
            inputs: [
                {
                    id: 1,
                    type: "text",
                    label: "Geburtsjahr",
                    default: null,
                    regex: '^[0-9]+$',
                },
                {
                    id: 2,
                    type: "select",
                    label: "Familienstand",
                    options: [
                        {
                            name: "verheiratet",
                            value: "verheiratet",
                            isDefault: true
                        },
                        {
                            name: "nicht verheiratet",
                            value: "nicht verheiratet",
                            isDefault: false
                        },
                    ]
                },
                {
                    id: 3,
                    type: "select",
                    label: "Berufsstatus",
                    options: [
                        {
                            name: "Angestellter/-e",
                            value: "Angestellter/-e",
                            isDefault: false,
                        },
                        {
                            name: "Selbständiger/-e",
                            value: "Selbständiger/-e",
                            isDefault: true,
                        },
                        {
                            name: "Student/-in",
                            value: "Student/-in",
                            isDefault: false,
                        },
                        {
                            name: "Nicht erwerbstätig",
                            value: "Nicht erwerbstätig",
                            isDefault: false,
                        },
                    ]
                },
                {
                    id: 4,
                    type: "select",
                    label: "Jährliches Einkommen",
                    options: [
                        {
                            name: "keine Angabe",
                            value: "keine Angabe",
                            isDefault: true,
                        },
                        {
                            name: "mindestens 66.600 €",
                            value: "mindestens 66.600 €",
                            isDefault: false,
                        },
                        {
                            name: "weniger als 66.600 €",
                            value: "weniger als 66.600 €",
                            isDefault: false,
                        },
                    ]
                },
                {
                    id: 5,
                    type: "select",
                    label: "Vorversicherung",
                    options: [
                        {
                            name: "keine Angabe",
                            value: "keine Angabe",
                            isDefault: true,
                        },
                        {
                            name: "PKV",
                            value: "PKV",
                            isDefault: false,
                        },
                        {
                            name: "GKV-Mitgliedschaft",
                            value: "GKV-Mitgliedschaft",
                            isDefault: false,
                        },
                        {
                            name: "GKV-Familienversicherung",
                            value: "GKV-Familienversicherung",
                            isDefault: false,
                        },
                    ]
                },
            ]
        },
        {
            id: 1,
            name: "Kind",
            icon: <FamilyRestroomIcon />,
            maxCount: 3,
            inputs: [
                {
                    id: 1,
                    type: "text",
                    label: "Geburtsjahr",
                    default: null,
                    regex: '^[0-9]+$',
                },
                {
                    id: 2,
                    type: "select",
                    label: "Gemeinsames Kind",
                    options: [
                        {
                            name: "Ja",
                            value: "Ja",
                            isDefault: true
                        },
                        {
                            name: "Nein",
                            value: "Nein",
                            isDefault: false
                        },
                    ]
                }
            ]
        },
    ]
}

const dakFormDescriptor = {
    main: [
        {
            id: 0,
            type: "select",
            label: "Mitarbeiter",
            options: [
                {
                    name: "0",
                    value: "0",
                    isDefault: true,
                },
                {
                    name: "1 bis 5",
                    value: "1 bis 5",
                    isDefault: false,
                },
                {
                    name: "6 bis 20",
                    value: "6 bis 20",
                    isDefault: false,
                },
                {
                    name: "21 bis 50",
                    value: "21 bis 50",
                    isDefault: false,
                },
                {
                    name: "51 bis 200",
                    value: "51 bis 200",
                    isDefault: false,
                },
                {
                    name: "201+",
                    value: "201+",
                    isDefault: false,
                },
            ]
        }
    ],
    roles: []
}

const INTEGER_REGEX = new RegExp('^[0-9]*$');;

const removeLeadingZeros = (str) => {
    if (str == "0") {
        return str;
    }
    else {
        return str.replace(/^0+/, '');
    }
}

const toMinPrice = (str) => {

    const price = parseInt(str);
    if (isNaN(price) || price <= 0) {
        return 1;
    }
    else {
        return price;
    }
}
export default function AddLead(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const reload = props.reload;

    const open = props.open;
    const setOpen = props.setOpen;

    const [businessId, setBusinessId] = useState(props.businessId)

    const [categoryId, setCategoryId] = useState(0)
    const [firm, setFirm] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [birthDate, setBirthdate] = useState(props.birthYear == null ? "2005" : props.birthYear)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [street, setStreet] = useState("")
    const [houseNo, setHouseNo] = useState(props.houseNo == null ? "" : props.houseNo)
    const [zipCode, setZipCode] = useState(props.zipCode == null ? "" : props.zipCode)
    const [town, setTown] = useState(props.town == null ? "" : props.town)
    const [country, setCountryId] = useState("Deutschland")
    const [comment, setComment] = useState("") 
    const [paymentTerm, setPaymentTerm] = useState(3)  
 
    const [hasDeclaredConsent, setHasDeclaredConsent] = useState(false)


    const [isValidFirm, setIsValidFirm] = useState(true)
    const [isValidFirstName, setIsValidFirstName] = useState(false)
    const [isValidLastName, setIsValidLastName] = useState(false)
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
    const [isValidEmailAddress, setIsValidEmailAddress] = useState(false)
    const [isValidStreet, seIsValidStreet] = useState(false)
    const [isValidHouseNo, setIsValidHouseNo] = useState(false)
    const [isValidZipCode, setIsValidZipCode] = useState(false)
    const [isValidTown, setIsValidTown] = useState(false)
    const [isValidComment, setIsValidComment] = useState(true)

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    const [categoryList, setCategoryList] = useState([]);
    const [updateProductList, setUpdateProductList] = useState(true);


    const [countryList, setCountryList] = useState([]);
    const [updateCountryList, setUpdateCountryList] = useState(true);

    const [updateForm, setUpdateForm] = useState(true);
    const [formInstance, setFormInstance] = useState(null);
    const [formData, setFormData] = useState(null);

    const categoryIdFormDescriptorMapping = {
        0: pkvFormDescriptor,
        2: dakFormDescriptor,
    }

    const [updateInputValueDict, setUpdateInputValueDict] = useState(false);

    const [isBudgetAvailable, setBudgetAvailable] = useState(false);

    const [mode, setMode] = useState(MODE_AUCTION)
    const [minBid, setMinBid] = useState(1)
    const [price, setPrice] = useState(1)

    if(props.businessId != businessId) {   
        if(props.firm != null) {
            setFirm(props.firm)
        }   
        if(props.firstName != null) {
            setFirstName(props.firstName)
        }   
        if(props.lastName != null) {
            setLastName(props.firstName)
        }
        if(props.phoneNo != null) { 
            setPhoneNumber(props.phoneNo)
            handleValidationResponse(validateNonEmptyPhoneNumber(props.phoneNo), (response) => setIsValidPhoneNumber(response), () => setIsValidPhoneNumber(false), appAPI.showMessage);
        } 
        if(props.emailAddress != null) { 
            setEmailAddress(props.emailAddress)
            handleValidationResponse(validateNonEmptyEmailAddress(props.emailAddress), (response) => setIsValidEmailAddress(response), () => setIsValidEmailAddress(false), appAPI.showMessage);
        } 
        if(props.street != null) { 
            setStreet(props.street)
            handleValidationResponse(validateNonEmptyAlphanumericString(props.street), (response) => seIsValidStreet(response), () => seIsValidStreet(false), appAPI.showMessage);
        } 
        if(props.houseNo != null) {  
            setHouseNo(props.houseNo)
            handleValidationResponse(validateNonEmptyHouseNo(props.houseNo), (response) => setIsValidHouseNo(response), () => setIsValidHouseNo(false), appAPI.showMessage);
        } 
        if(props.zipCode != null) { 
            setZipCode(props.zipCode)
            handleValidationResponse(validateNonEmptyZipCode(props.zipCode), (response) => setIsValidZipCode(response), () => setIsValidZipCode(false), appAPI.showMessage);

        } 
        if(props.town != null) { 
            setTown(props.town)  
            handleValidationResponse(validateNonEmptyAlphanumericString(props.town), (response) => setIsValidTown(response), () => setIsValidTown(false), appAPI.showMessage);
        } 
        setBusinessId(props.businessId)     
        setUpdateForm(true); 
    }  

    const update = () => setUpdateInputValueDict(updateInputValueDict ? false : true)

    const submit = (declared, mail, phone) => {
        if (mode == MODE_ASSIGNMENT) {
            assign(declared, mail, phone)
        }
        else if (mode == MODE_AUCTION) {
            createAuction(declared, mail, phone)
        }
        else {
            createOffer(declared, mail, phone)
        }
    }

    const updateMainFormData = (inputId, value) => {
        formData.main[inputId].value = value;
        formData.main[inputId].isValid = formData.main[inputId].regex == null || INTEGER_REGEX.test(value)
        update();
    }

    const updateRoleFormData = (roleId, inputId, value) => {
        formData.roleInstances[roleId][inputId].value = value
        formData.roleInstances[roleId][inputId].isValid = formData.roleInstances[roleId][inputId].regex == null || new RegExp(formData.roleInstances[roleId][inputId].regex).test(value)
        update();
    }

    const doUpdateForm = () => {
        if (categoryId != null) {
            if (categoryIdFormDescriptorMapping[categoryId] != null) {
                let [formInstance, formData] = instantiate(categoryIdFormDescriptorMapping[categoryId]);
                setFormData(formData)
                setFormInstance(formInstance)
            }
            else {
                setFormData(null);
                setFormInstance(null);
            }
        }
        setUpdateForm(false)
    }

    const close = () => {
        setCategoryId(0);
        setPrice(1);
        setMinBid(1);
        setFirm(null);
        setFirstName(null);
        setLastName(null);
        setBirthdate("2005");
        setPhoneNumber(null);
        setEmailAddress(null);
        setStreet(null);
        setHouseNo(null);
        setZipCode(null);
        setTown(null);
        setCountryId("Deutschland");
        setComment(null);
        setOpen(false)
        setFormData(null)
        setFormInstance(null)
        setUpdateForm(true)
        setIsValidFirm(true);
        setIsValidFirstName(false)
        setIsValidLastName(false)
        setIsValidPhoneNumber(false)
        setIsValidEmailAddress(false)
        seIsValidStreet(false)
        setIsValidHouseNo(false)
        setIsValidZipCode(false) 
        setIsValidTown(false)
        setIsValidComment(true)
        setPaymentTerm(3)
        setBusinessId(null)
    }

    const buildCommentStr = () => {
        let commentStr = ""
        if (formData != null) {
            let mainKeyArr = Object.keys(formData.main)
            for (let index = 0; index < mainKeyArr.length; index++) {
                let key = mainKeyArr[index];
                commentStr += formData.main[key].label + ": " + formData.main[key].value + "$";
            }
            let roleKeyArr = Object.keys(formData.roleInstances);
            for (let roleIndex = 0; roleIndex < roleKeyArr.length; roleIndex++) {
                let role = formData.roleInstances[roleKeyArr[roleIndex]];
                commentStr += "$" + role.name + "$"
                let inputKeyArr = Object.keys(role)
                for (let inputIndex = 0; inputIndex < inputKeyArr.length; inputIndex++) {
                    let key = inputKeyArr[inputIndex];
                    if (role[key].label != null && role[key].value != null) {
                        commentStr += role[key].label + ": " + role[key].value + "$";
                    }
                }
            }
        }
        return (comment != null ? comment + "$$" : "") + commentStr;
    }

    const assign = async (declared, mail, phone) => {
        handleResponse(isBudgetAvailableAPI(contextAPI.username, contextAPI.password, 40), (response) => {
            if (response) {
                handleResponse(assignAPI(
                    contextAPI.username,
                    contextAPI.password,
                    firm,
                    firstName,
                    lastName,
                    birthDate,
                    phoneNumber,
                    emailAddress,
                    street,
                    houseNo,
                    zipCode,
                    town,
                    country,
                    categoryId,
                    buildCommentStr(),
                    declared,
                    mail,
                    phone
                ),
                    (response) => {
                        reload()
                        close()
                        appAPI.showMessage(MSG_CHANGES_SAVED);
                    },
                    appAPI.showMessage)
            }
        }, appAPI.showMessage);

    }

    const createAuction = async (declared, mail, phone) => {

        handleResponse(createAuctionAPI(
            contextAPI.username,
            contextAPI.password,
            firm,
            firstName,
            lastName,
            birthDate,
            phoneNumber,
            emailAddress,
            street,
            houseNo,
            zipCode,
            town,
            country,
            categoryId,
            buildCommentStr(),
            minBid,
            declared,
            mail,
            phone, 
            paymentTerm
        ),
            (response) => {
                reload()
                close()
                appAPI.showMessage(MSG_CHANGES_SAVED);
            },
            appAPI.showMessage
        )
    }


    const createOffer = async (declared, mail, phone) => {

        handleResponse(createOfferAPI(
            contextAPI.username,
            contextAPI.password,
            firm,
            firstName,
            lastName,
            birthDate,
            phoneNumber,
            emailAddress,
            street,
            houseNo,
            zipCode,
            town,
            country,
            categoryId,
            buildCommentStr(),
            price,
            declared,
            mail,
            phone,
            paymentTerm
        ),
            (response) => {
                reload()
                close()
                appAPI.showMessage(MSG_CHANGES_SAVED);
            },
            appAPI.showMessage
        )
    }

    useEffect(() => {
        if (updateForm) {
            doUpdateForm();
        }

        if (updateCountryList) {
            handleResponse(fetchCountriesAPI(contextAPI.username, contextAPI.password), (response) => { setCountryList(response); setUpdateCountryList(false) }, appAPI.showMessage);
        }

        if (updateProductList) {
            handleResponse(fetchCategoriesAPI(contextAPI.username, contextAPI.password), (response) => {
                for (let index = 0; index < response.length; index++) {
                    const category = response[index];
                    if (category.id == 0) {
                        category.formDescriptor = pkvFormDescriptor;
                    }
                    else {
                        category.formDescriptor = null;
                    }
                }
                setCategoryList(response);
                setUpdateProductList(false)

            }, appAPI.showMessage);
        }

        handleResponse(isBudgetAvailableAPI(contextAPI.username, contextAPI.password, 60), (response) => {
            setBudgetAvailable(response)
            if (response) {
                if (!contextAPI.admin) {
                    setMode(MODE_ASSIGNMENT)
                }
            }
            else if (mode == MODE_ASSIGNMENT) {
                setMode(MODE_AUCTION)

            }
        }, appAPI.showMessage);
    })

    const years = [];

    let key = 0;


    for (let year = new Date().getFullYear() - 18; year >= 1960; year--) {
        years.push(year.toString())
    }
    return (
        <Box>
            <Dialog
                fullScreen
                open={open}
                onClose={close}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: PRIMARY_COLOR }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {LABEL_ADD_LEAD}
                        </Typography>
                        <Button
                            disabled={!(isValidFirm && isValidFirstName && isValidLastName && isValidPhoneNumber && isValidEmailAddress && isValidStreet && isValidStreet && isValidHouseNo && isValidZipCode && isValidTown && isValidComment && isValid(formData))}
                            autoFocus
                            color="inherit"
                            onClick={() => setIsConfirmationOpen(true)}>
                            {LABEL_SAVE}
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box style={{ display: "block", padding: "50px", marginLeft: "auto", marginRight: "auto", maxWidth: "1024px", marginTop: "50px", marginBottom: "50px" }}>
                    <ConfirmationDialog
                        isConfirmationOpen={isConfirmationOpen}
                        setIsConfirmationOpen={setIsConfirmationOpen}
                        submit={submit}
                        phoneNumber={phoneNumber}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box style={{ height: "25px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Lead-Art</InputLabel>
                                <Select
                                    value={categoryId != null ? categoryId : ""}
                                    onChange={(e) => { setCategoryId(e.target.value); setUpdateForm(true); }}
                                    label={LABEL_PRODUCT}
                                >
                                    {
                                        categoryList.map((category) => {
                                            return <MenuItem key={category.categoryId} value={category.categoryId}>{category.categoryName}</MenuItem>
                                        })
                                    }
                                    <MenuItem value={""} disabled></MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Modus</InputLabel>
                                <Select
                                    value={mode}
                                    onChange={(e) => setMode(e.target.value)}
                                    label={LABEL_PRODUCT}
                                    disabled={isBudgetAvailable && !contextAPI.admin}
                                >
                                    <MenuItem value={MODE_ASSIGNMENT} disabled={!isBudgetAvailable}>Abonnement</MenuItem>
                                    <MenuItem value={MODE_OFFER}>Sofortkauf</MenuItem>
                                    <MenuItem value={MODE_AUCTION}>Auktion</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            mode == MODE_AUCTION ?
                                <Grid item xs={12}>
                                    <TextField
                                        value={minBid == null ? "" : minBid}
                                        label={"Mindestgebot in €"}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            if (INTEGER_REGEX.test(value) || value == "") {
                                                setMinBid(toMinPrice(removeLeadingZeros(e.target.value)))
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                :
                                null
                        }


                        {
                            mode == MODE_OFFER ?
                                <Grid item xs={12}>
                                    <TextField
                                        value={price == null ? "" : price}
                                        label={"Preis in €"}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            if (INTEGER_REGEX.test(value) || value == "") {
                                                setPrice(toMinPrice(removeLeadingZeros(e.target.value)))
                                            }
                                        }}
                                        disabled={mode == MODE_ASSIGNMENT}
                                        fullWidth
                                    />
                                </Grid>
                                :
                                null
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{LABEL_PAYMENT_TERM}</InputLabel>
                                <Select 
                                    value={paymentTerm}
                                    onChange={(e) => setPaymentTerm(e.target.value)}
                                >
                                    <MenuItem value={3}>3 {LABEL_DAYS}</MenuItem>
                                    <MenuItem value={7}>7 {LABEL_DAYS}</MenuItem>
                                    <MenuItem value={14}>14 {LABEL_DAYS}</MenuItem>
                                    <MenuItem value={30}>30 {LABEL_DAYS}</MenuItem>
                                </Select>
                            </FormControl>  
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ marginTop: "25px", marginBottom: "25px", borderColor: PRIMARY_COLOR }} />
                            <Paper style={{padding: "20px", backgroundColor: "red", color: "white", lineHeight: "1.5rem"}}>
                                <WarningIcon style={{color: "white", float: "left", marginRight: "10px"}}/>
                                <Typography style={{lineHeight: "2rem"}}>Alle Angaben bis auf die Firma, den Vornamen, den Nachnamen, die Telefonnummer, die Emailadresse, sowie die Straße und Hausnummer sind für potentielle Käufer vor Kaufabschluss einsehbar!</Typography>
                            </Paper>
                            <Box style={{ marginTop: "25px", marginBottom: "25px", borderColor: PRIMARY_COLOR }} />

                        </Grid>
                        <Grid item xs={12}> 
                            <TextField
                                value={firm == null ? "" : firm}
                                label={LABEL_FIRM}
                                onChange={(e) => {
                                    handleValidationResponse(validatePossiblyEmptyAlphanumericString(e.target.value), (response) => setIsValidFirm(response), () => setIsValidFirm(false), appAPI.showMessage);
                                    setFirm(e.target.value)
                                }}
                                fullWidth
                                error={!isValidFirm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField
                                value={firstName == null ? "" : firstName}
                                label={LABEL_FIRST_NAME}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyAlphanumericString(e.target.value), (response) => setIsValidFirstName(response), () => setIsValidFirstName(false), appAPI.showMessage);
                                    setFirstName(e.target.value)
                                }}
                                error={!isValidFirstName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField
                                value={lastName == null ? "" : lastName}
                                label={LABEL_LAST_NAME}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyAlphanumericString(e.target.value), (response) => setIsValidLastName(response), () => setIsValidLastName(false), appAPI.showMessage);
                                    setLastName(e.target.value)
                                }}
                                error={!isValidLastName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl fullWidth>
                                <InputLabel>{LABEL_YEAR_OF_BIRTH}</InputLabel>
                                <Select
                                    value={birthDate}
                                    onChange={(e) => { setBirthdate(e.target.value) }}
                                >
                                    {
                                        years.map((year) => {
                                            return (
                                                <MenuItem key={year} value={year}>{year}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={phoneNumber == null ? "" : phoneNumber}
                                label={LABEL_PHONE_NUMBER}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyPhoneNumber(e.target.value), (response) => setIsValidPhoneNumber(response), () => setIsValidPhoneNumber(false), appAPI.showMessage);
                                    setPhoneNumber(e.target.value)
                                }}
                                error={!isValidPhoneNumber}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={emailAddress == null ? "" : emailAddress}
                                label={LABEL_EMAIL_ADDRESS}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyEmailAddress(e.target.value), (response) => setIsValidEmailAddress(response), () => setIsValidEmailAddress(false), appAPI.showMessage);
                                    setEmailAddress(e.target.value)
                                }}
                                error={!isValidEmailAddress}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={street == null ? "" : street}
                                label={LABEL_STREET}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyAlphanumericString(e.target.value), (response) => seIsValidStreet(response), () => seIsValidStreet(false), appAPI.showMessage);
                                    setStreet(e.target.value)
                                }}
                                error={!isValidStreet}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={houseNo == null ? "" : houseNo}
                                label={LABEL_HOUSE_NO}

                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyHouseNo(e.target.value), (response) => setIsValidHouseNo(response), () => setIsValidHouseNo(false), appAPI.showMessage);
                                    setHouseNo(e.target.value)
                                }}
                                error={!isValidHouseNo}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={zipCode == null ? "" : zipCode}
                                label={LABEL_ZIP_CODE}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyZipCode(e.target.value), (response) => setIsValidZipCode(response), () => setIsValidZipCode(false), appAPI.showMessage);
                                    setZipCode(e.target.value)
                                }}
                                error={!isValidZipCode}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={town == null ? "" : town}
                                label={LABEL_TOWN}
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyAlphanumericString(e.target.value), (response) => setIsValidTown(response), () => setIsValidTown(false), appAPI.showMessage);
                                    setTown(e.target.value)
                                }}
                                fullWidth
                                error={!isValidTown}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Land</InputLabel>
                                <Select
                                    value={country != null ? country : ""}
                                    onChange={(e) => setCountryId(e.target.value)}
                                >
                                    {
                                        countryList.map((country) => {
                                            return <MenuItem key={country} value={country}>{country}</MenuItem>
                                        })
                                    }
                                    <MenuItem value={""} disabled></MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={comment == null ? "" : comment}
                                label={LABEL_COMMENT}
                                multiline
                                rows={5}
                                fullWidth
                                onChange={(e) => {
                                    handleValidationResponse(validateNonEmptyAlphanumericString(e.target.value), (response) => setIsValidTown(response), () => setIsValidTown(false), appAPI.showMessage);
                                    setComment(e.target.value)
                                }}
                            />
                        </Grid>

                        {
                            formInstance != null ?
                                formInstance.main.map((input) =>
                                    <Grid item xs={12} key={input.id}>
                                        {
                                            input.type === "text" ?
                                                <TextField
                                                    label={input.label}
                                                    value={toEmptyStringIfNull(formData.main[input.id].value)}
                                                    error={!formData.main[input.id].isValid}
                                                    onChange={(e) => updateMainFormData(input.id, e.target.value)}
                                                    fullWidth
                                                    onChance
                                                />
                                                :
                                                <FormControl fullWidth>
                                                    <InputLabel>{input.label}</InputLabel>
                                                    <Select
                                                        value={toEmptyStringIfNull(formData.main[input.id].value)}
                                                        onChange={(e) => updateMainFormData(input.id, e.target.value)}
                                                    >
                                                        {
                                                            input.options.map((option) => {
                                                                return <MenuItem key={key++} value={option.value}>{option.name}</MenuItem>
                                                            })
                                                        }
                                                        {toEmptyStringIfNull(formData.main[input.id]) === "" ? <MenuItem key={key++} value={""}></MenuItem> : null}
                                                    </Select>
                                                </FormControl>
                                        }
                                    </Grid>
                                )
                                :
                                null
                        }
                        {
                            formInstance != null ?
                                formInstance.roles.map((role) => {
                                    let first = true;
                                    return (
                                        role.inputs.map(input => {
                                            const jsx = (
                                                <Grid item xs={12} key={key++} style={{ marginTop: first ? "50px" : "0px" }}>
                                                    {
                                                        first ?
                                                            <Grid container>
                                                                <Grid item xs={11}>
                                                                    <IconButton style={{ marginTop: "5px", marginRight: "10px", float: "left", color: PRIMARY_COLOR }} onClick={() => {
                                                                        const [formInstanceCpy, formDataCpy] = removeRole(formInstance, formData, role.id);
                                                                        setFormInstance(formInstanceCpy);
                                                                        setFormData(formDataCpy);
                                                                    }}><DeleteIcon /></IconButton>
                                                                    <Typography variant="h6" style={{ color: PRIMARY_COLOR, marginTop: "10px", marginBottom: "25px" }}>{role.name}</Typography>
                                                                </Grid>
                                                            </Grid> : null
                                                    }
                                                    {
                                                        input.type === "text" ?
                                                            <TextField
                                                                label={input.label}
                                                                value={toEmptyStringIfNull(formData.roleInstances[role.id][input.id].value)}
                                                                error={!formData.roleInstances[role.id][input.id].isValid}
                                                                onChange={(e) => updateRoleFormData(role.id, input.id, e.target.value)}
                                                                fullWidth
                                                            />
                                                            :
                                                            <FormControl fullWidth>
                                                                <InputLabel>{input.label}</InputLabel>
                                                                <Select
                                                                    value={toEmptyStringIfNull(formData.roleInstances[role.id][input.id].value)}
                                                                    onChange={(e) => updateRoleFormData(role.id, input.id, e.target.value)}
                                                                >
                                                                    {
                                                                        input.options.map((option) => {
                                                                            return <MenuItem key={key++} value={option.value}>{option.name}</MenuItem>
                                                                        })
                                                                    }
                                                                    {toEmptyStringIfNull(formData.roleInstances[role.id][input.id]) === "" ? <MenuItem key={key++} value={""}></MenuItem> : null}
                                                                </Select>
                                                            </FormControl>
                                                    }
                                                </Grid>
                                            )
                                            first = false;
                                            return jsx;
                                        })
                                    )
                                }).flat()
                                :
                                null
                        }
                        <Grid item xs={12} style={{ marginTop: "50px" }}>
                            {
                                formInstance != null && categoryId != null && categoryIdFormDescriptorMapping[categoryId] != null ?
                                    categoryIdFormDescriptorMapping[categoryId].roles.map((role) => {
                                        return (
                                            <Button
                                                key={key++}
                                                startIcon={role.icon}
                                                size="small" onClick={() => {
                                                    const [locFormInstance, locFormData] = addRole(categoryIdFormDescriptorMapping[categoryId], formInstance, formData, role.id)
                                                    setFormInstance(locFormInstance)
                                                    setFormData(locFormData)
                                                    update();
                                                }}
                                                style={{ marginRight: "15px", backgroundColor: formInstance.roleInstanceCounts[role.id] >= role.maxCount ? "grey" : SECONDARY_COLOR, color: "white" }}
                                                disabled={formInstance.roleInstanceCounts[role.id] >= role.maxCount}
                                                variant="outlined"
                                            >
                                                {role.name}
                                            </Button>
                                        )
                                    })
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Box>
    );
}


function ConfirmationDialog(props) {

    const [mail, setMail] = useState(true);
    const [phone, setPhone] = useState(false);
    const [declaration, setDeclaration] = useState(false);

    const genButtonStyle = (bColor) => { return { display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "50px", color: bColor } }

    const iconStyle = { width: "75px", height: "75px" }


    const chooseMail = () => {
        setMail(true);
        setPhone(false);
        setDeclaration(false);
    }


    const choosePhone = () => {
        setMail(false);
        setPhone(true);
        setDeclaration(false);
    }

    const chooseDeclartion = () => {
        setMail(false);
        setPhone(false);
        setDeclaration(true);
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.isConfirmationOpen}
        >
            <Paper style={{ width: "70%" }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ padding: "25px", color: PRIMARY_COLOR }}>
                            Einwilligung in die Datenverarbeitung
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <IconButton onClick={chooseMail} style={genButtonStyle(mail ? SECONDARY_COLOR : "grey")}>
                            <EmailIcon style={iconStyle} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <IconButton disabled={props.phoneNumber == null || !props.phoneNumber.startsWith("00491")} onClick={choosePhone} style={genButtonStyle(phone ? SECONDARY_COLOR : "grey")}>
                            <PhoneAndroidIcon style={iconStyle} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <IconButton onClick={chooseDeclartion} style={genButtonStyle(declaration ? SECONDARY_COLOR : "grey")}>
                            <GavelIcon style={iconStyle} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" style={{ display: "block", backgroundColor: SECONDARY_COLOR, marginRight: "auto", marginLeft: "auto", marginBottom: "25px" }} onClick={() => {
                            props.setIsConfirmationOpen(false);
                            props.submit(declaration, mail, phone);
                        }}>Abschließen</Button>
                        {
                            declaration ?
                                <Box style={{ textAlign: "center", marginBottom: "25px" }}>
                                    <Typography>{DECLARTION_OF_CONSENT}</Typography>
                                </Box>
                                :
                                null
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Backdrop>
    );
} 