import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button, Divider, InputLabel, LinearProgress, Tab, Tabs, Typography, CardContent, Paper, CircularProgress, Table, TableHead, TableRow, TableBody, TableCell, ListItem, ListItemText, } from '@mui/material';
import { buildUrl, formatParam, HTTP_STATUS_SUCCESS, PRIMARY_COLOR, SECONDARY_COLOR } from './../App';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import ReceiptIcon from '@mui/icons-material/Receipt';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




import { LABEL_CONFIRMATION_LINK, LABEL_COUNTRY, LABEL_PRICE, LABEL_PRIVATE_COMMENT, LABEL_SAVE, handleResponse, handleValidationResponse, toEmptyStringIfNull } from './../Utility';
import { abortSaleAPI, acceptPriceAPI, addNote, addNoteAPI, fetchLeadAPI, proposeCancellationAPI, fetchNotes, fetchNotesAPI, fetchSalesStatusAPI, findProductInvoicesAPI, findTransactionInvoicesAPI, handleClaimAPI, makeClaimAPI, onSaveEmailAddress, onSaveFirm, onSaveFirstName, onSaveHouseNo, onSaveLastName, onSavePhoneNumber, onSaveStreet, onSaveTown, onSaveZipCode, proposePriceAPI, recreateAuctionAPI, recreateOfferAPI, approveCancellationAPI, reissueProductInvoiceAPI } from '../api/lead_api';
import { TabPanel, a11yProps } from '../Utility';
import EditTextDialog from '../components/EditTextDialog';
import { LeadReview } from './Review';
import { validateNonEmptyAlphanumericString, validateNonEmptyAlphanumericText } from '../api/validation_api';
import DownloadIcon from '@mui/icons-material/Download';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import EditCurrencyDialog from '../components/EditCurrencyDialog';
import { fetchSalesCsvAPI } from '../api/reporting_api';
import { fetchInvoiceAPI } from '../api/invoice_api';
import { InvoicingDetails } from './Invoice';
import { Link, NavLink } from 'react-router-dom';
import { fetchUserCanSellAPI } from '../api/user_api';

const LABEL_CATEGORY = "Art";
const LABEL_FIRM = "Firma";
const LABEL_FIRST_NAME = "Vorname";
const LABEL_LAST_NAME = "Nachname";
const LABEL_BIRTHDATE = "Geburtsdatum";
const LABEL_PHONE_NUMBER = "Telefonnummer";
const LABEL_EMAIL_ADDRESS = "Emailadresse";
const LABEL_STREET = "Straße";
const LABEL_HOUSE_NO = "Hausnummer";
const LABEL_ZIP_CODE = "Postleitzahl";
const LABEL_TOWN = "Ort";

const LABEL_DETAILS = "Details";
const LABEL_NOTES = "Notizen";
const LABEL_PURCHASE = "Kauf";
const LABEL_CLAIM = "Reklamationen";
const LABEL_ACCEPT_CLAIM = "Akzeptieren";
const LABEL_REJECT_CLAIM = "Zurückweisen";
const LABEL_SALE = "Verkauf";
const LABEL_NOTE = "Notizen";
const LABEL_REVIEW = "Bewertungen";
const LABEL_COMMENT = "Kommentar"

const LABEL_CHANGE_PRICE = "Preis ändern"

export default function Lead(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;


    const leadId = props.leadId;

    const [tabIndex, setTabIndex] = useState(0);
    const [lead, setLead] = useState(null);
    const [updateLead, setUpdateLead] = useState(true);
    const [buyerView, setBuyerView] = useState(true)
    const [creatorView, setCreatorView] = useState(false)

    useEffect(() => {

        const doUpdateLead = async () => {
            handleResponse(fetchLeadAPI(contextAPI.username, contextAPI.password, leadId), (response) => {
                setLead(response);
                setUpdateLead(false);
                setBuyerView(contextAPI.id === response.buyerId)
                setCreatorView(contextAPI.id === response.creatorId)
            }, appAPI.showMessage)
        }

        if (updateLead) {
            doUpdateLead();
        }

    })

    let nextTabIndex = 0;

    const printLeadHeading = (lead) => {
        if (lead.businessName !== "" && lead.businessName !== null && ((lead.firstName !== "" && lead.firstName !== null) || (lead.lastName !== "" && lead.lastName !== null))) {
            return lead.categoryName + ": " + lead.businessName + " " + "(" + lead.firstName + " " + lead.lastName + ")"
        }
        else if (lead.businessName !== "" && lead.businessName !== null) {
            return lead.categoryName + ": " + lead.businessName;
        }
        else {
            return lead.categoryName + ": " + lead.firstName + " " + lead.lastName
        }
    }

    const leadColor = (lead) => {
        if (lead.status == "CONFIRMATION_REQUIRED") {
            return "gray";
        }
        else if (lead.status == "FOR_SALE") {
            return SECONDARY_COLOR
        }
        else if (lead.status == "SOLD" && lead.proposedPrice == null) {
            return PRIMARY_COLOR
        }
        else if (lead.status == "NOT_SOLD" || lead.status == "INVALIDATED") {
            return "red"
        }
        else if (lead.status == "CONFIRMATION_FAILED") {
            return "black"
        }
        else if (lead.status == "COMPLAINED" || lead.status == "INVALIDATION_PROPOSED" || lead.proposedPrice != null) {
            return "orange"
        }
    }

    return (
        lead == null ?
            null
            :
            <Box>
                <Accordion style={{ marginBottom: "50px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                        style={
                            {
                                backgroundColor: leadColor(lead),
                                color: "white"
                            }
                        }
                    >
                        <Typography variant="span" style={{ fontFamily: "Arial, Helvetica" }}>
                            {printLeadHeading(lead)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "0px" }}>
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <LeadDetails
                                lead={lead}
                            />
                        </TabPanel>
                        {
                            buyerView ?
                                <TabPanel value={tabIndex} index={nextTabIndex++}>
                                    <Notes
                                        leadId={leadId}
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                </TabPanel>
                                :
                                null
                        }
                        {
                            buyerView ?
                                <TabPanel value={tabIndex} index={nextTabIndex++}>
                                    <Purchase
                                        lead={lead}
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                        reload={() => setUpdateLead(true)}
                                    />
                                </TabPanel>
                                :
                                null
                        }
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <Claim
                                contextAPI={contextAPI}
                                appAPI={appAPI}
                                lead={lead}
                                reload={() => setUpdateLead(true)}
                            />
                        </TabPanel>
                        {
                            creatorView ?
                                <TabPanel value={tabIndex} index={nextTabIndex++}>
                                    <Sale
                                        contextAPI={contextAPI}
                                        appAPI={appAPI}
                                        lead={lead}
                                        reload={() => setUpdateLead(true)}
                                    />
                                </TabPanel>
                                :
                                null
                        }
                        <TabPanel value={tabIndex} index={nextTabIndex++}>
                            <LeadReview
                                lead={lead}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                readOnly={!buyerView}
                            />
                        </TabPanel>
                        <Divider style={{ marginTop: "50px" }} />
                        <Tabs value={tabIndex} onChange={(event, newValue) => { setTabIndex(newValue) }} TabIndicatorProps={{ style: { background: PRIMARY_COLOR } }}>
                            <Tab label={LABEL_DETAILS} {...a11yProps(0)} />
                            {
                                buyerView ?
                                    <Tab label={LABEL_NOTES} {...a11yProps(1)} />
                                    :
                                    null
                            }
                            {
                                buyerView ?
                                    <Tab label={LABEL_PURCHASE} {...a11yProps(2)} />
                                    :
                                    null
                            }
                            <Tab disabled={true} label={LABEL_CLAIM} {...a11yProps(4)} />

                            {
                                creatorView ?
                                    <Tab label={LABEL_SALE} disabled={!(lead.status == "SOLD" || lead.status == "NOT_SOLD" || lead.status == "FOR_SALE" || lead.status == "INVALIDATION_PROPOSED")}{...a11yProps(3)} />
                                    :
                                    null
                            }

                            <Tab label={LABEL_REVIEW} {...a11yProps(5)} />
                        </Tabs>
                    </AccordionDetails>
                </Accordion>
            </Box>
    )
}

export function LeadDetails(props) {

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.id)}
                    label={"ID"}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.categoryName)}
                    label={LABEL_CATEGORY}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={toEmptyStringIfNull(new Date(props.lead.creationTimestamp))}
                    label={"Datum"}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.businessName)}
                    label={LABEL_FIRM}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6} >
                <TextField
                    value={toEmptyStringIfNull(props.lead.firstName)}
                    label={LABEL_FIRST_NAME}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6} >
                <TextField
                    value={toEmptyStringIfNull(props.lead.lastName)}
                    label={LABEL_LAST_NAME}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} >
                <TextField
                    value={toEmptyStringIfNull(props.lead.birthDate)}
                    label={LABEL_BIRTHDATE}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.phoneNumber)}
                    label={LABEL_PHONE_NUMBER}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.emailAddress)}
                    label={LABEL_EMAIL_ADDRESS}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.street)}
                    label={LABEL_STREET}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.houseNo)}
                    label={LABEL_HOUSE_NO}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.zipCode)}
                    label={LABEL_ZIP_CODE}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.town)}
                    label={LABEL_TOWN}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.country)}
                    label={LABEL_COUNTRY}
                    variant="standard"
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={toEmptyStringIfNull(props.lead.comment).replaceAll("$", "\n")}
                    label={LABEL_COMMENT}
                    variant="standard"
                    fullWidth
                    disabled
                    multiline
                />
            </Grid>
        </Grid>
    )
}

function Notes(props) {

    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const leadId = props.leadId;

    const [newNote, setNewNote] = useState(null)

    const [noteList, setNoteList] = useState([])
    const [updateNoteList, setUpdateNoteList] = useState([])

    const [isValid, setIsValid] = useState(true)


    const addNote = (noteStr) => {
        handleResponse(addNoteAPI(contextAPI.username, contextAPI.password, leadId, noteStr), (response) => { appAPI.showMessage("Änderungen gespeichert."); setUpdateNoteList(true) }, appAPI.showMessage)
    }

    useEffect(() => {

        const doUpdateNotList = async () => {
            setNoteList(await handleResponse(fetchNotesAPI(contextAPI.username, contextAPI.password, leadId), (response) => { setNoteList(response) }, appAPI.showMessage));
        }

        if (updateNoteList) {
            doUpdateNotList();
            setUpdateNoteList(false)
        }
    })

    return (
        <Grid container spacing={5}>
            {
                noteList.sort((a, b) => b.id - a.id).map((note) => {
                    const createTime = new Date(note.creationTimestamp);
                    return (
                        <Grid key={note.id} item xs={12}>
                            <TextField
                                value={toEmptyStringIfNull(note.note)}
                                label={createTime.toLocaleString()}
                                variant="standard"
                                multiline
                                fullWidth
                                disabled
                                rows={5}
                            />
                        </Grid>
                    )
                })
            }
            <Grid item xs={12}>
                <TextField
                    style={{ marginTop: "50px" }}
                    value={toEmptyStringIfNull(newNote)}
                    label={LABEL_NOTE}
                    variant="standard"
                    multiline
                    rows={5}
                    fullWidth
                    onChange={(e) => {
                        handleValidationResponse(validateNonEmptyAlphanumericText(e.target.value), (response) => setIsValid(response), () => setIsValid(false), appAPI.showMessage)
                        setNewNote(e.target.value)
                    }}
                    error={!isValid}
                />
                <Button variant="contained" style={{ marginTop: "25px", color: "white", backgroundColor: SECONDARY_COLOR }} onClick={() => {
                    addNote(newNote);
                    setNewNote(null);
                }} disabled={newNote === null || newNote === "" || !isValid}>Anlegen</Button>

            </Grid>
        </Grid>
    )
}


function Purchase(props) {

    const lead = props.lead;
    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const reload = props.reload;


    const updatePrice = () => {
        handleResponse(acceptPriceAPI(contextAPI.username, contextAPI.password, lead.id, lead.proposedPrice), () => reload(), appAPI.showMessage);
    }

    const approveCancellation = (accept) => {
        handleResponse(approveCancellationAPI(contextAPI.username, contextAPI.password, lead.id, accept), () => reload(), appAPI.showMessage);
    }


    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="div" style={{ marginTop: "0px" }}>
                        {LABEL_PRICE}: {toEmptyStringIfNull(lead.price)} EUR
                    </Typography>
                    {
                        lead.proposedPrice != null ?
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Preisvorschlag: {lead.proposedPrice} EUR
                            </Typography>
                            :
                            null
                    }

                    <Grid container spacing={3} style={{ marginTop: "15px" }}>
                        <Grid item xs={12} md={6}>
                            <NavLink to={"/invoicing/" + lead.id} style={{ textDecoration: 'none', color: 'unset' }} >
                                <Button variant="outlined" startIcon={<ReceiptIcon />} fullWidth>
                                    Rechunungen anzeigen
                                </Button>
                            </NavLink >
                        </Grid>
                        {
                            lead.proposedPrice != null ?
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => updatePrice()} fullWidth>
                                        Preisvorschlag annehmen
                                    </Button>
                                </Grid>
                                :
                                null
                        }
                        {
                            lead.status == "INVALIDATION_PROPOSED" ?
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => approveCancellation(true)} fullWidth>
                                        Stornierung bestätigen
                                    </Button>
                                </Grid>
                                :
                                null
                        }
                        {
                            lead.status == "INVALIDATION_PROPOSED" ?
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => approveCancellation(false)} fullWidth>
                                        Stornierung zurückweisen
                                    </Button>
                                </Grid>
                                :
                                null
                        }
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginTop: "25px" }}>Verkäufer</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={toEmptyStringIfNull(lead.sellerFirstName)}
                        label={LABEL_FIRST_NAME}
                        variant="standard"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={toEmptyStringIfNull(lead.sellerLastName)}
                        label={LABEL_LAST_NAME}
                        variant="standard"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={toEmptyStringIfNull(lead.sellerEmailAddress)}
                        label={LABEL_EMAIL_ADDRESS}
                        variant="standard"
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const calcProgress = (startTime, duration) => {
    let progress = 100;
    const elapsedTime = (Date.now() - startTime);
    if (elapsedTime < duration) {
        progress = Math.round((elapsedTime / duration) * 100)
    }
    return progress;
}


function Sale(props) {

    const lead = props.lead;
    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const reload = props.reload;

    const [initialized, setInitialized] = useState(false);
    const [salesStatus, setSalesStatus] = useState(null);
    const [showChangePriceDiaglog, setShowChangePriceDiaglog] = useState(false);
    const [showRequestCancellationDialog, setShowRequestCancellationDialog] = useState(false);


    const [updateInvoiceList, setUpdateInvoiceList] = useState(true);
    const [productInvoiceList, setProductInvoiceList] = useState([]);
    const [transactionInvoiceList, setTransactionInvoiceList] = useState([]);

    useEffect(() => {

        if (updateInvoiceList) {
            handleResponse(findProductInvoicesAPI(contextAPI.username, contextAPI.password, lead.id), (invoiceList) => setProductInvoiceList(invoiceList), appAPI.showMessage);
            handleResponse(findTransactionInvoicesAPI(contextAPI.username, contextAPI.password, lead.id), (invoiceList) => setTransactionInvoiceList(invoiceList), appAPI.showMessage);
            setUpdateInvoiceList(false);
        }

    })

    const updateSalesStatus = () => handleResponse(fetchSalesStatusAPI(contextAPI.username, contextAPI.password, lead.id), (salesStatus) => {
        if (salesStatus == null) {
            reload();
        }
        else {
            setSalesStatus(salesStatus)
        }
    }, appAPI.showMessage)

    const updatePrice = (price) => {
        handleResponse(proposePriceAPI(contextAPI.username, contextAPI.password, lead.id, price), () => reload(), appAPI.showMessage);
        setShowChangePriceDiaglog(false);
    }

    const proposeCancellation = () => {
        handleResponse(proposeCancellationAPI(contextAPI.username, contextAPI.password, lead.id), () => reload(), appAPI.showMessage);
        setShowRequestCancellationDialog(false)
    }

    const reissueProductInvoice = () => handleResponse(reissueProductInvoiceAPI(contextAPI.username, contextAPI.password, lead.id), () => reload(), appAPI.showMessage);


    const abort = () => handleResponse(abortSaleAPI(contextAPI.username, contextAPI.password, lead.id), (aborted) => {
        if (!aborted) {
            appAPI.showMessage("Abbruch nicht möglich.")
        }
        reload()
    }, appAPI.showMessage)

    useEffect(() => {
        if (!initialized) {
            updateSalesStatus()
            setInitialized(true);
            window.setTimeout(() => setInitialized(false), 1000);
        }
    })


    const isAuction = false;
    const isActive = true;
    const price = null;
    const minBid = 0;
    const endDate = new Date();


    return (
        <Box>

            {
                lead.status == "NOT_SOLD" ?
                    <Resell
                        appAPI={appAPI}
                        contextAPI={contextAPI}
                        lead={lead}
                        reload={reload}
                    />
                    :
                    null
            }
            {
                lead.status == "SOLD" || lead.status == "INVALIDATION_PROPOSED" ?
                    <Grid container spacing={3}>
                        <Grid item xs={12}>

                            <Typography variant="h5" component="div" style={{ marginTop: "0px" }}>
                                {LABEL_PRICE}: {toEmptyStringIfNull(lead.price)} EUR
                            </Typography>
                            {
                                lead.proposedPrice != null ?
                                    <Box>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {"Preisvorschlag:"} {lead.proposedPrice} EUR
                                        </Typography>
                                    </Box>
                                    :
                                    null
                            }
                            <Grid container spacing={3} style={{marginTop: "15px"}}>
                                <Grid xs={12} md={6} item>
                                    <NavLink to={"/invoicing/" + lead.id} style={{ textDecoration: 'none', color: 'unset' }} >
                                        <Button variant="outlined" startIcon={<ReceiptIcon />} fullWidth>
                                            Rechunungen anzeigen
                                        </Button>
                                    </NavLink >

                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => { setShowChangePriceDiaglog(true) }} disabled={lead.proposedPrice != null ||lead.status == "INVALIDATION_PROPOSED"} fullWidth>
                                        Preis vorschlagen
                                    </Button>

                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => { setShowRequestCancellationDialog(true)}} disabled={lead.status == "INVALIDATION_PROPOSED" || lead.proposedPrice != null } fullWidth>
                                        Stornieren
                                    </Button>
                                </Grid>
                            </Grid>

                            <EditCurrencyDialog
                                open={showChangePriceDiaglog}
                                onClose={() => { setShowChangePriceDiaglog(false) }}
                                onSave={updatePrice}
                                dialogTitle={LABEL_CHANGE_PRICE}
                            />
                            <RequestCancellationDiaglog
                                open={showRequestCancellationDialog}
                                onClose={() => setShowRequestCancellationDialog(false)}
                                onCancel={proposeCancellation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ marginTop: "25px" }}>Käufer</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={toEmptyStringIfNull(lead.buyerFirstName)}
                                label={LABEL_FIRST_NAME}
                                variant="standard"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={toEmptyStringIfNull(lead.buyerLastName)}
                                label={LABEL_LAST_NAME}
                                variant="standard"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={toEmptyStringIfNull(lead.buyerEmailAddress)}
                                label={LABEL_EMAIL_ADDRESS}
                                variant="standard"
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                    :
                    null
            }
            {
                lead.status == "FOR_SALE" ?
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box style={{ display: "inline-block" }}>
                                <CardContent>
                                    {
                                        salesStatus == null ?
                                            null
                                            :
                                            salesStatus.isAuction ?
                                                <Box>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        {salesStatus.curPrice != null ? "Aktuelles Gebot" : null}
                                                    </Typography>
                                                    <Typography variant="h5" component="div" style={{ color: PRIMARY_COLOR }}>
                                                        {salesStatus.curPrice == null ? "Kein Gebot" : salesStatus.curPrice + " EUR"}
                                                    </Typography>
                                                    <Typography variant="p" component="div" style={{ color: PRIMARY_COLOR }}>
                                                        {salesStatus.curPrice == null ? "Mindestgebot: " + salesStatus.minBid + " EUR" : ""}
                                                    </Typography>
                                                </Box>
                                                :
                                                <Box>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        Preis im Sofortkauf
                                                    </Typography>
                                                    <Typography variant="h5" component="div" style={{ color: PRIMARY_COLOR }}>
                                                        {salesStatus.curPrice + " EUR"}
                                                    </Typography>
                                                </Box>
                                    }


                                    <Box style={{ marginTop: "5px" }}>
                                        {
                                            salesStatus != null ?
                                                <CircularProgress
                                                    style={{ color: PRIMARY_COLOR, marginLeft: "5px" }}
                                                    value={calcProgress(salesStatus.startTime, salesStatus.duration)}
                                                    size={12}
                                                    thickness={25}
                                                    variant="determinate"
                                                />
                                                :
                                                null
                                        }
                                        <Typography
                                            style={{ display: "inline-block", marginLeft: "5px", fontSize: 14 }}
                                            color="text.secondary"
                                            gutterBottom>
                                            {salesStatus != null && endDate != null ? "Ende" + ": " + new Date(salesStatus.startTime + salesStatus.duration).toLocaleDateString() + " um " + new Date(salesStatus.startTime + salesStatus.duration).toLocaleTimeString() + " Uhr" : null}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Grid>
                        <Grid item>
                            {
                                salesStatus != null ?
                                    <Button variant="contained" onClick={abort} startIcon={<CancelIcon />} style={{ backgroundColor: SECONDARY_COLOR, marginRight: "25px" }} disabled={salesStatus != null && salesStatus.isAuction && salesStatus.curPrice != null}>
                                        Abbrechen
                                    </Button>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                    :
                    null
            }

        </Box>
    )
}

function RequestCancellationDiaglog(props) {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Storno
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten Sie die Stornierung des Leads veranlassen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>Stornieren</Button>
                <Button onClick={props.onClose} autoFocus>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
}


function Claim(props) {

    const [claimStr, setClaimStr] = useState(props.lead.claimStr)
    const [isValid, setIsValid] = useState(true)

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI
    const lead = props.lead

    const makeClaim = () => {
        handleResponse(makeClaimAPI(contextAPI.username, contextAPI.password, lead.id, claimStr), (response) => { appAPI.showMessage("Änderungen gespeichert."); props.reload() }, appAPI.showMessage)
    }

    const handleClaim = (accept) => {
        handleResponse(handleClaimAPI(contextAPI.username, contextAPI.password, lead.id, accept), (response) => { appAPI.showMessage("Änderungen gespeichert."); props.reload() }, appAPI.showMessage)
    }



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    value={claimStr != null ? claimStr : ""}
                    label={"Begründung"}
                    variant="standard"
                    fullWidth
                    multiline
                    rows={4}
                    disabled={lead.status != "SOLD" || contextAPI.id != lead.buyerId}
                    onChange={(e) => {
                        handleValidationResponse(validateNonEmptyAlphanumericText(e.target.value), (response) => setIsValid(response), () => setIsValid(false), appAPI.showMessage)
                        setClaimStr(e.target.value)
                    }
                    }
                    error={!isValid}
                />
            </Grid>
            <Grid item xs={12}>
                {
                    contextAPI.id == lead.buyerId && lead.status == "SOLD" ?
                        <Button disabled={claimStr == "" || !isValid} variant="contained" style={{ marginRight: "30px", color: "white", backgroundColor: SECONDARY_COLOR }} onClick={makeClaim}>
                            {LABEL_CLAIM}
                        </Button>
                        :
                        null
                }
                {
                    contextAPI.id == lead.creatorId && lead.status == "COMPLAINED" ?
                        <Button variant="contained" style={{ marginRight: "30px", color: "white", backgroundColor: SECONDARY_COLOR }} onClick={() => {
                            handleClaim(true)
                        }}>{LABEL_ACCEPT_CLAIM}</Button>
                        :
                        null
                }

                {
                    contextAPI.id == lead.creatorId && lead.status == "COMPLAINED" ?
                        <Button variant="contained" style={{ color: "white", backgroundColor: SECONDARY_COLOR }} onClick={() => {
                            handleClaim(false)
                        }}>{LABEL_REJECT_CLAIM}</Button>
                        :
                        null
                }
            </Grid>
        </Grid>
    )
}


function Resell(props) {

    const lead = props.lead;
    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const [mode, setMode] = useState(0);
    const [price, setPrice] = useState(1);
    const [disabled, setDisabled] = useState(false);
    const [canSell, setCanSell] = useState(false);
    const [updateCanSell, setUpdateCanSell] = useState(true);

    const reacreateOffer = () => {
        handleResponse(recreateOfferAPI(contextAPI.username, contextAPI.password, lead.id, price), (response) => { appAPI.showMessage("Angebot angelegt."); props.reload() }, appAPI.showMessage)
    }

    const recreateAuction = () => {
        handleResponse(recreateAuctionAPI(contextAPI.username, contextAPI.password, lead.id, price), (response) => { appAPI.showMessage("Auktion gestartet."); props.reload() }, appAPI.showMessage)
    }



    useEffect(() => {
        if (updateCanSell) {
            handleResponse(fetchUserCanSellAPI(contextAPI.id, contextAPI.username, contextAPI.password), (response) => { setCanSell(response); setUpdateCanSell(false); }, appAPI.showMessage);
        }
    })

    const resell = () => {
        setDisabled(true);
        if (mode == 0) {
            reacreateOffer()
        }
        else {
            recreateAuction()
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Modus</InputLabel>
                    <Select
                        value={mode}
                        onChange={(e) => setMode(e.target.value)}
                        label={""}
                        disabled={false}
                    >
                        <MenuItem value={0}>Sofortkauf</MenuItem>
                        <MenuItem value={1}>Auktion</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={price}
                    label={mode == 0 ? "Preis" : "Mindestgebot"}
                    onChange={(e) => setPrice(toMinPrice(e.target.value))}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Button disabled={disabled || !canSell} variant="contained" style={{ marginTop: "25px", color: "white", backgroundColor: SECONDARY_COLOR }} onClick={resell}>
                    {mode == 0 ? "Anlegen" : "Starten"}
                </Button>
            </Grid>
        </Grid>

    )

}

const toMinPrice = (str) => {

    const price = parseInt(str);
    if (isNaN(price) || price <= 0) {
        return 1;
    }
    else {
        return price;
    }
}


function Paypal() {
    return (
        <PayPalScriptProvider options={{ clientId: "test" }} sytle={{ width: "100%" }}>
            <PayPalButtons style={{ layout: "horizontal", width: "20%" }} />
        </PayPalScriptProvider>
    );
}