import { Backdrop, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./App";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { acceptOfferAPI } from "./api/user_api";
import { handleResponse } from "./Utility";

const LABEL_CONTRACT_OVERVIEW = "Vertragsübersicht"

const LABEL_FIRM = "Firma";
const LABEL_PRENAME = "Vorname";
const LABEL_LASTNAME = "Nachname"

const LABEL_SELLER = "Verkäufer";
const LABEL_BUYER = "Käufer";
const LABEL_SUBSCRIBER = "Abonnent";

const LABEL_ROLES = "Rollen";
const LABEL_NOTICE_PERIOD = "Kündigungsfrist";
const LABEL_DAYS = "Tage(e)";
const LABEL_FEES = "Gebühren";
const LABEL_TRANSACTION_FEES = "Transaktionsgebühren";

const LABEL_TERMS = "Allgemeine Geschäftsbedingungen";
const LABEL_ACCEPT_TERMS = "Es gelten die allgemeinen Geschäftsbedingungen.";


export default function LegalOffer(props) {

    const context = props.context;
    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const acceptOffer = () => {
        handleResponse(acceptOfferAPI(context.id, context.username, props.password),(response) => contextAPI.update(), appAPI.showMessage)
    }
  
    return ( 

        <Box>  
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <Typography style={{ color: PRIMARY_COLOR, fontWeight: "bold", fontSize: "1.5rem" }}>{LABEL_CONTRACT_OVERVIEW}</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label={LABEL_FIRM}
                                value={context.firm != null ? context.firm : ""}
                                disabled
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label={LABEL_PRENAME}
                                value={context.firstName != null ? context.firstName : ""}
                                disabled
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label={LABEL_LASTNAME}
                                value={context.lastName != null ? context.lastName : ""}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Typography style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>{LABEL_ROLES}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={context.callAgent} disabled={true} />}
                                label={LABEL_SELLER}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={context.purchaser} disabled={true} />}
                                label={LABEL_BUYER}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={context.subscriber} disabled={true} />}
                                label={LABEL_SUBSCRIBER}
                                style={{ marginLeft: "0px", marginTop: "12px" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    context.contract.offeredNoticePeriod != null ?
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <Typography style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>{LABEL_NOTICE_PERIOD}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography> {context.contract.offeredNoticePeriod.periodInDays} {LABEL_DAYS}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        null
                }
                {
                    context.contract.offeredFee != null ?
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <Typography style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>{LABEL_FEES}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        label={LABEL_TRANSACTION_FEES}
                                        value={context.contract.offeredFee.fee + " %"}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        null
                }
                {
                    context.contract.offeredTerms != null ?
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <Typography style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>{LABEL_TERMS}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{LABEL_ACCEPT_TERMS}</Typography>
                                    <IconButton href={context.contract.offeredTerms.termsUrl} target="_blank" style={{ marginTop: "15px", color: SECONDARY_COLOR }}>
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: SECONDARY_COLOR, marginTop: "25px" }}
                                onClick={acceptOffer}
                            >
                                Einwilligen
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    )


} 