import {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { handleValidationResponse, toEmptyStringIfNull } from "../Utility";

const LABEL_SAVE = "Speichern";
const LABEL_ABORT = "Abbrechen"
 
export default function EditTextDialog(props) {

    const appAPI = props.appAPI;
    const validate = props.validate;


    const [curValue, setCurValue] = useState(null)
    const [isValid, setValid] = useState(props.validate == null);

    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField value={toEmptyStringIfNull(curValue)} onChange={(e) => {
                    if(validate != null && appAPI != null) {
                        handleValidationResponse(validate(e.target.value), (response) => setValid(response), () => setValid(false), appAPI.showMessage)
                    }
                    setCurValue(e.target.value)
                }} id="name" variant="standard" fullWidth autoFocus error={!isValid}/>
            </DialogContent>
            <DialogActions> 
                <Button onClick={() => {props.onClose(); setCurValue(null)}}>{LABEL_ABORT}</Button>
                <Button onClick={() => {props.onSave(curValue); setCurValue(null)}} disabled={!isValid}>{LABEL_SAVE}</Button>
            </DialogActions>
        </Dialog>  
    ) 
}      