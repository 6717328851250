import { Box, Typography } from "@mui/material";
import { PRIMARY_COLOR } from "../App";

const HEADING_DATA_PROTECTION = "Datenschutz"

export default function Privacy(props) {

    return(
        <Box style={{fontFamily: "Arial, Helvetica"}}>
            <Typography variant="h1" style={{color: PRIMARY_COLOR}}>{HEADING_DATA_PROTECTION}</Typography>
            <Box style={{height: "50px"}}/>
            <Typography variant="p">
            </Typography>
            
        </Box>
    )

}